import React from 'react';
import WidgetWrapper from 'components/WidgetsList/WidgetWrapper/WidgetWrapper';
import './LocationPickerWidget.scss';

const LocationPickerWidget = ({ data }) => {
    const { Locations } = data;
    const maxColumns = 3;  // Maximum number of columns you want to have.
    const numberOfLocations = Locations.length;
    let remainingLocations = numberOfLocations;
    let rows = [];

    // Calculate rows
    while (remainingLocations > 0) {
        const itemsInRow = remainingLocations >= maxColumns ? maxColumns : remainingLocations;
        rows.push(itemsInRow);
        remainingLocations -= itemsInRow;
    }

    return (
        <WidgetWrapper data={data}>
            <div className="tw-bg-white   overflow-visible">
                <div className="lg:tw-grid lg:tw-grid lg:tw-grid-cols-12 lg:tw-gap-4">
                    <h2 className="tw-text-2xl tw-mb-3 tw-col-span-4 tw-text-4xl tw-font-light">{data.LocationsTitle}</h2>
                    <div className="tw-mb-5 tw-col-span-8 tw-text-primary tw-text-4xl tw-font-thin" dangerouslySetInnerHTML={{ __html: data.LocationsDescription }} />
                </div>
                <div className="tw-grid tw-gap-4">
                    {rows.map((rowSize, rowIndex) => (
                        <div key={rowIndex} className={`tw-grid md:tw-grid-cols-${rowSize} tw-gap-4 tw-gap-y-4 tw-bottom-0`}>
                            {Locations.slice(maxColumns * rowIndex, maxColumns * rowIndex + rowSize).map((location, index) => (
                                <div key={index} className="tw-flex tw-relative tw-flex-col tw-items-center tw-justify-center tw-bg-gray-50 tw-rounded  tw-text-center overflow-visible">
                                    <a href={location.LocationUrl} className="tw-no-underline tw-w-full">
                                        <div className="tw-overflow-hidden tw-relative">
                                            <img src={location.LocationImages.Url} alt={location.LocationImages.Name} className="tw-transition-all tw-duration-300 tw-transform hover:tw-scale-105 tw-w-full" />
                                        </div>
                                        <div className="fade-out-black tw-left-0 tw-text-white tw-w-full tw-flex tw-items-center tw-justify-between tw-p-4 tw-absolute tw-bottom-0 tw-z-10">
                                            <h6 className="tw-m-0 tw-text-white tw-font-montserrat">{location.LocationName}</h6>
                                            <span className="tw-no-underline tw-text-xl tw-flex tw-gap-3 tw-font-thin">
                                                VIEW
                                                <img src="/clientImages/triangle.svg" className="tw-"/>
                                            </span>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </WidgetWrapper>
    );
};

export default LocationPickerWidget;