import React, { useEffect, useState, useContext } from 'react';

import SocialNetworks from 'components/SocialNetworks/SocialNetworks';
import styles from './Footer.module.css';
import { SiteSettingsContext } from '../../contexts/SiteSettingsProvider';
import CookiePolicyMessage from './CookiePolicyMessage/CookiePolicyMessage';
import JsContent from 'components/Helpers/JsContent';
import MailchimpForm from 'components/Helpers/MailchimpForm';

const Footer = () => {
  const settings = useContext(SiteSettingsContext);
  const [ContactByTelephoneText, setContactByTelephoneText] = useState();
  const [ContactTelephone, setContactTelephone] = useState();
  const [ContactBySocialNetworksText, setContactBySocialNetworksText] = useState();

  const [col1Title, setCol1Title] = useState();
  const [col2Title, setCol2Title] = useState();

  const [cookiePolicyMessage, setCookiePolicyMessage] = useState();

  useEffect(() => {
     
    if (settings){
      setContactByTelephoneText(settings.FooterTexts.ContactByTelephoneText);
      setContactTelephone(settings.FooterTexts.ContactTelephone);
      setContactBySocialNetworksText(settings.FooterTexts.ContactBySocialNetworksText);

      setCol1Title(settings.FooterTexts.FooterColumn1Title);
      setCol2Title(settings.FooterTexts.FooterColumn2Title);

      setCookiePolicyMessage(settings.FooterTexts.CookiePolicyMessage);
    }
  }, [settings]); 

  let nav1Items;
  let nav2Items;
  if (settings) {
    let nav1 = settings.BotNav1;
    let nav2 = settings.BotNav2;

    if (nav1){
      nav1Items = nav1.map((item, index) =>
        <li key={index} >
          <a href={item.Url}>
            {item.Name}
          </a>
        </li>
      );
    }

    if (nav2){
      nav2Items = nav2.map((item, index) =>
        <li key={index} >
          <a href={item.Url}>
            {item.Name}
          </a>
        </li>
      );
    }
  }

 
  if (settings){
    return (

      <>
        <MailchimpForm />

        <div className={styles.Footer + "  tw-mt-5 "}>
    
          <div className="mid-footer tw-border-b tw-border-zinc-600 tw-py-8 tw-font-montserrat">
            <div className="container">
              <div className="tw-grid md:tw-grid-cols-4">
                <div className="">
                  <h2 className='tw-text-primary tw-text-3xl tw-font-extralight'>{col1Title}</h2>
                  <ul className="tw-p-0 tw-m-0">
                    {nav1Items}
                  </ul>
                </div>
                <div className="tw-col-span-2 tw-max-w-[450px]">
                  <h2 className='tw-text-primary tw-text-3xl tw-font-extralight'>{col2Title}</h2>
                  <ul className="tw-p-0 tw-m-0 lg:tw-columns-2">
                    {nav2Items}
                  </ul>
                </div>
                <div className="tw-text-md">
                  {settings !== "" && <span dangerouslySetInnerHTML={{ __html: settings.FooterTexts.FooterColumn4Text }}></span>}

                </div>
                {/* <div className="">
                  {settings !== "" && <span dangerouslySetInnerHTML={{ __html: settings.FooterTexts.FooterColumn4Text }}></span>}
                </div> */}
              </div>
            </div>
          </div>
          <div className="bottom-footer tw-text-center tw-pt-5 tw-pb-8 tw-bg-primary_dark ">
            {/* <div className="container">
              {settings !== "" && <span dangerouslySetInnerHTML={{ __html: settings.FooterTexts.FooterBottom1Text }}></span>}
              {settings !== "" && <div>
                <span>Copyright © 2023</span> <span dangerouslySetInnerHTML={{ __html: settings.FooterTexts.FooterBottom2Text }}></span>
              </div>}
              <div><a href="https://www.ipro-software.com/web-design/" className="developed_by" alt="Website builder for your short stay agency" title="Mobile ready websites built specifically for the vacation rental industry" rel="noopener" target="_blank"  >Design by iPro Vacation Rental Websites</a></div>
            </div> */}

            <SocialNetworks socialNetworks={settings.SocialNetworks} size={'2xl'} wrapperclassName="tw-flex tw-gap-3 tw-justify-center md:tw-justify-end" /> 
          </div>

        </div>

        <CookiePolicyMessage text={cookiePolicyMessage} />

      </>
    );
  }

};

 

export default Footer;
