import React, { useEffect } from 'react';
import './mailcgimp.css';
const ConstantContactForm = () => {

    useEffect(() => {
        // Add the Constant Contact validation script dynamically
        const script = document.createElement('script');
        script.src = '//static.ctctcdn.com/js/signup-form-widget/current/signup-form-widget.min.js';
        script.type = 'text/javascript';
        script.async = true;
        script.defer = true;
        script.id = 'signupScript';
        document.body.appendChild(script);

        // Add the _ctct_m variable
        window._ctct_m = '4798728c41f7fc6c6d5f6c98841a0733';

        setTimeout(() => {
            // Select the input element by its ID
            var inputElement = document.getElementById("email_address_0");

            // Set the placeholder text
            if (inputElement) {  // Check if the element exists to avoid errors
                inputElement.placeholder = "TYPE EMAIL HERE";
            }
             
        }, 2500);

        // Cleanup function: remove the scripts when the component unmounts
        return () => {
            document.body.removeChild(script);
        }
    }, []); // Empty dependency array means this useEffect runs once when the component mounts

    return (
        <div id="constant_contact_subsc">
            <style type="text/css">
                {`
                    div#constant_contact_subsc {
                        background: url(/clientImages/emailbg.jpg) no-repeat center center;
                        background-size: cover;
                        position: relative;
                        z-index: 1;
                    }
                    #constant_contact_subsc::before {
                        content: "";
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background: linear-gradient(to bottom, rgba(29, 22, 22, 0.0576),   rgba(0, 0, 0, 0.6264),  rgba(29, 22, 22, 0)); /* Black overlay with 50% opacity */
                        z-index: 1;
                    }

                    div#gdpr_text {
                        display: none;
                    }
                    p.ctct-form-footer {
                        display: none;
                    }

                    button.ctct-form-button {
                        max-width: 200px;
                        max-height: 48px;
                    }
                    label#email_address_label_0 {
                        display: none;
                    }
                    h2.ctct-form-header {
                            max-width: 355px;
                            font-size: 29px !important;
                            font-weight: 200 !important;
                            color: #fff !important;
                            font-family: "Montserrat", sans-serif !important;
                    }
                    .ctct-form-embed.form_0 .ctct-form-defaults {
                        background-color: transparent;
                    }
                    .ctct-form-errorMessage {
                        position: absolute;
                        margin-top: 50px;
                       
                    }
                    div#email_address_field_0 {
                        margin: 0;

                    }
                    input#email_address_0 {
                        border: 0px solid #fff !important;
                        border-radius: 0 !important;
                    }
                    button.ctct-form-button {
                        border-radius: 0px !important;
                        background: #C5A05F !important;
                        border: 1px solid #C5A05F !important;
                    }
                    form#ctct_form_0 {
                        min-height: 300px;
                    }
                    .ctct-inline-form {
                        position: relative;
                        z-index: 1;
                    }
                    p.ctct-form-text {
                        color: #fff;
                    }
                    @media only screen and (max-width: 800px) {
                        input#email_address_0 {
                            margin-bottom: 15px;
                        }  
                    }
                    @media only screen and (max-width: 600px) {
                        .ctct-form-button {
                            width: 100% !important;
                            max-width: 100% !important;
                        }
                    }
                    
                    @media only screen and (min-width: 900px) {
                       form#ctct_form_0 {
                                  display: flex;
                                justify-content: center;
                                align-items: center;
                        }
                        h2.ctct-form-header {
                            margin-right: 75px !important;
                            line-height: 1 !important;
                        }
                    }
                `}
            </style>

            {/* Constant Contact Inline Form Code */}
            <div className="ctct-inline-form tw-max-w-full tw-overflow-hidden" data-form-id="3e62df14-b89d-416b-916f-4845f3a0fd41"></div>
        </div>
    );
};

export default ConstantContactForm;