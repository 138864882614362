import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './Navigation.scss';
import Skeleton from "react-loading-skeleton";
import { Outlet, Link } from "react-router-dom";
import { LanguageSwitcher } from 'components/LanguageSwitcher/LanguageSwitcher';
import { CurrencySelector } from 'components/CurrencySelector/CurrencySelector';
import { PropertyInputSearch } from 'components/PropertyInputSearch/PropertyInputSearch';
import { NavigationMenu } from 'components/NavigationMenu/NavigationMenu';
import { NavigationMegaMenu } from 'components/NavigationMegaMenu/NavigationMegaMenu';
import GoogleTranslateSwitcher from 'components/GoogleTranslateSwitcher/GoogleTranslateSwitcher';
import { BrowserRouter } from 'react-router-dom';
import useWindowSize from "utils/useWindowSize";
import { faHeart as fasHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Navigation = (settings) => {
  const { width } = useWindowSize();
  const { height } = useWindowSize();
 
  let [isLoading, setIsLoading] = useState(true);

  let [enablenavigation, setEnablenavigation] = useState('');
  let [enableDropdown, setEnableDropdown] = useState();
  let [navigationDepth, setNavigationDepth] = useState();
  let [showHome, setShowHome] = useState();
  let [centerNav, setCenterNav] = useState();
 
  let [megaMenu, setMegaMenu] = useState();
  let [enableNavigationLogo, setEnableNavigationLogo] = useState();
  let [showWishlist, setShowWishlist] = useState();
  let [showWishlistUrl, setShowWishlistUrl] = useState();
  let [showWishlistName, setShowWishlistName] = useState();
  let [showguestLogin, setShowGuestLogin] = useState();
  let [guestLoginUrlName, setGuestLoginUrlName] = useState();
  let [guestLoginUrl, setGuestLoginUrl] = useState();
 
  let [showPhoneNumberNavigation, setShowPhoneNumberNavigation] = useState();

  let [menuNodes, setMenuNodes] = useState([]);
  
  let [showCurrencySelector, setShowCurrencySelector] = useState();
  let [showLanguageSwitcher, setShowLanguageSwitcher] = useState();
  let [showShowGoogleTranslate, setShowGoogleTranslate] = useState();
  let [showEnableSearch, setEnableSearch] = useState();

  let [callUsDictionary, setCallUsDictionary] = useState();

  let [currency, setCurrency] = useState();
  let [currecncies, setCurrencies] = useState();
  let [contactTelephone, setContactTelephone] = useState();
  let [desktopLogo, setDesktopLogo] = useState();
  let [desktopLogoHeight, setDesktopLogoHeight] = useState();
  let [desktopLogoWidth, setDesktopLogoWidth] = useState();
  let [mobileLogo, setMobileLogo] = useState();
  let [mobileLogoHeight, setMobileLogoHeight] = useState();
  let [mobileLogoWidth, setMobileLogoWidth] = useState();

  let [headerColorClass, setHeaderColorClass] = useState('');

  let [languages, setLanguages] = useState('');
  let [language, setLanguage] = useState('');
  let [navOpen, setNavOpen] = useState(false);

  useEffect(() => {
 
    if (settings.navigationSettings.CenterNavigationBar){
      setCenterNav('center');
    }else{
      setCenterNav('end');
    }
 
    setContactTelephone(settings.siteSettings.ContactTelephone);
    setCallUsDictionary(settings.navigationSettings.CallUsDictionary);
     
    setEnableDropdown(settings.navigationSettings.EnableDropdowns);
    setMegaMenu(settings.navigationSettings.EnableMegaMenu);
    setEnableNavigationLogo(settings.navigationSettings.EnableNavigationLogo);
    setEnableSearch(settings.navigationSettings.Enablesearch);
    setShowGuestLogin(settings.navigationSettings.GuestLoginNavigation);
    setGuestLoginUrlName(settings.navigationSettings.GuestLoginUrlName);
    setGuestLoginUrl(settings.navigationSettings.GurestLoginUrl);
    setMenuNodes(settings.navigationSettings.TopRightMenuNodes);
 
    
    setNavigationDepth(settings.navigationSettings.NavigationDepth);
    setShowPhoneNumberNavigation(settings.navigationSettings.PhoneNumberNavigation);
    setShowCurrencySelector(settings.navigationSettings.ShowHideCurrencySelector);
    setShowHome(settings.navigationSettings.ShowHomeInTheNavigation);
    setShowLanguageSwitcher(settings.navigationSettings.LanguageSwitcher);
    setShowWishlist(settings.navigationSettings.WishlistNavigation);
    setShowWishlistUrl(settings.navigationSettings.WishlistUrl);
    setShowWishlistName(settings.navigationSettings.WishlistUrlName);

    setCurrencies(settings.currencies);
  
    setCurrency(settings.currency);
 
    setDesktopLogo(settings.siteSettings.DesktopLogo);
    setDesktopLogoHeight(settings.siteSettings.DesktopLogoHeight);
    setDesktopLogoWidth(settings.siteSettings.DesktopLogoWidth);
    setMobileLogo(settings.siteSettings.MobileLogo);
    setMobileLogoHeight(settings.siteSettings.MobileLogoHeight);
    setMobileLogoWidth(settings.siteSettings.MobileLogoWidth);
    setShowGoogleTranslate(settings.navigationSettings.ShowGoogleTranslate);

    setLanguages(settings.languages);
    setLanguage(settings.language);

    if (settings.bodyClasses.includes("navigation-dark")){
      setHeaderColorClass('navigation-dark tw-bg-primary text-white');
    } 

    if (settings.bodyClasses.includes("navigation-light") || settings.bodyClasses.includes("navigation-light") === undefined) {
      setHeaderColorClass('navigation-light text-black');
    }  
 
  }, [settings]);

  useEffect(() => {

    let nav = document.getElementsByTagName('body')[0].getAttribute('nav');
 
    if (nav === "1") {
      setEnablenavigation(true);
      return;
    }
    setEnablenavigation(false);
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("sitesettings") === null) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1600);
    } else {
      setIsLoading(false);
    }
  }, []);

  function expandNav(){
    setNavOpen(!navOpen);
  }

  const closeMenu = () => {
    
    setNavOpen(false);
  } 

   
 
  if (!enablenavigation) {
    return;
  }
 
  return (
    <>
 
      {!isLoading ? 
        <div className={" "}>
          <div className={`${headerColorClass} Navigation tw-px-3 tw-py-5 md:tw-py-1 md:tw-z-30 tw-flex tw-items-center tw-justify-between  xl:tw-items-center`}>
            {enableNavigationLogo && <div className="navigation-logo lg:tw-flex tw-flex tw-justify-between tw-items-center lg:tw-row-span-3 tw-grid-rows-2">
              {desktopLogo !== null ?
                <a href="/">
                  <img src={desktopLogo} className="img-responsive tw-hidden md:tw-inline desktop-logo" />
                </a>
                : null}
              {mobileLogo !== null ?
                <a href="/">
                  <img src={mobileLogo} className="img-responsive md:tw-hidden mobile-logo" />
                </a>
                : null
              }


            </div>

            }

            <>
              {width < 1024 ? <div className="tw-space-y-2 tw-order-3" onClick={expandNav}>
                <div className="tw-w-8 tw-h-0.5 tw-bg-gray-600"></div>
                <div className="tw-w-8 tw-h-0.5 tw-bg-gray-600"></div>
                <div className="tw-w-8 tw-h-0.5 tw-bg-gray-600"></div>
              </div> : null}
            </>

            <div className="tw-flex lg:tw-justify-between lg:tw-items-center tw-gap-3 tw-w-full">

              <NavigationMenu menuNodes={menuNodes} showWishlistName={showWishlistName} showWishlistUrl={showWishlistUrl} showWishlist={showWishlist} theme={settings.bodyClasses} open={navOpen} closeMenu={closeMenu} navigationDepth={navigationDepth} />
              {/* {megaMenu && <NavigationMegaMenu menuNodes={menuNodes} theme={settings.bodyClasses} open={navOpen} closeMenu={closeMenu} />} */}

              <div className="service-menu lg:tw-flex lg:tw-justify-between lg:tw-items-center lg:tw-gap-3">
                <div className=" tw-flex tw-justify-between lg:tw-justify-between lg:tw-items-center lg:tw-gap-3 tw-items-center">
                  {showPhoneNumberNavigation &&
                    <a href={'tel:' + contactTelephone.toString()} className="tw-no-underline">
                      {callUsDictionary + " "}
                      <span className="site-phone-text">{contactTelephone}</span>
                    </a>
                  }

                  {showguestLogin &&
                    <a href={guestLoginUrl} className="tw-no-underline" target="_blank" rel="noopener">

                      <span className="guestLoginUrl">{guestLoginUrlName}</span>
                    </a>
                  }


                  {showLanguageSwitcher && <LanguageSwitcher languages={languages} language={language} />}

                  {showCurrencySelector && <CurrencySelector currecncies={currecncies} />}



                </div>

                {showEnableSearch && width > 1024 && <PropertyInputSearch />}



                {/* {showShowGoogleTranslate && <GoogleTranslateSwitcher />} */}
              </div>

              {(showWishlist && width > 1024) &&
                <a href={showWishlistUrl} className="btn btn-primary tw-no-underline !tw-flex tw-h-fit tw-items-center !tw-px-9 !tw-py-3" target="_blank" rel="noopener">
                  <FontAwesomeIcon
                    icon={farHeart}
                    color="white"
                    size={'md'}
                    className={'tw-mr-3'}

                  />
                  <span className="showWishlistName tw-text-white">{showWishlistName}</span>
                </a>
              }

            </div>






          </div>
         <div className="tw-p-3">
            {showEnableSearch && width < 1024 && <PropertyInputSearch />}
         </div>
        </div>: 

        <div className={"tw-py-3 tw-bg-primary"}>
         
          <div className="container-fluid " >
            <div className={`${headerColorClass} Navigation tw-p-3 tw-relative tw-z-30 lg:tw-grid lg:tw-grid-cols-2  xl:tw-flex tw-justify-between xl:tw-items-center`}>
              {enableNavigationLogo && <div className="navigation-logo lg:tw-flex tw-flex tw-justify-between tw-items-center lg:tw-row-span-3 tw-grid-rows-2">
                {desktopLogo !== null ?
                       <Skeleton height={30} width={100} />  
                  : null}
                {mobileLogo !== null ?
                  <a href="/">
                    <Skeleton height={30} width={300} />  
                  </a>
                  : null
                }

            
              </div>
              }    
              {
                <div className="tw-hidden lg:tw-grid 2xl:tw-flex lg:tw-justify-stretch lg:tw-items-center tw-gap-3">
                  <div className="tw-block tw-p-0 tw-m-0 lg:tw-flex lg:tw-justify-between tw-gap-3">
                    <Skeleton height={20} width={80}  />  
                    <Skeleton height={20} width={80} />  
                    <Skeleton height={20} width={80} /> 
                    <Skeleton height={20} width={80} />  
                    <Skeleton height={20} width={80} />  
                    <Skeleton height={20} width={80} />   
                  </div>
                  
                </div>
              }
            </div>
          </div >
        </div >
      
      
      }
 
      
      <Outlet />
    </>
  );
};

Navigation.propTypes = {};

Navigation.defaultProps = {};

export default Navigation;
