import './App.css';
import './custom.scss';
import React, { useState, useEffect } from 'react';
import MasterTemplate from './layouts/MasterTemplate/MasterTemplate';
import { SiteSettingsProvider } from './contexts/SiteSettingsProvider';
 
function App() {
 
  return (
    <div className="main-app">
      <SiteSettingsProvider>
          <MasterTemplate />
      </SiteSettingsProvider> 
    </div>
  );
}
  
export default App;
