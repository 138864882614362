import React, { useState, useEffect, createContext } from 'react';

const SiteSettingsContext = createContext({});
const TemplateContext = createContext('');
const PageIdContext = createContext('');
const GetSiteDataContext = createContext(() => { });
const CurrencyContext = createContext('');

function SiteSettingsProvider({ children }) {
    const [siteSettings, setSiteSettings] = useState(null);
    const [template, setTemplate] = useState('');
    const [pageId, setPageId] = useState('');
    const [currency, setCurrency] = useState('');

    const getSiteData = () => {
        let urlLocation = window.location.pathname;
        fetch(`${process.env.REACT_APP_BASE_URL}apis/wp?u=${urlLocation}`)
            .then(response => response.json())
            .then(data => {
                const pageId = data.Id;
                setPageId(pageId);
                setTemplate(data.Type);

                // Check if the site settings are already cached in sessionStorage

                const cachedSiteSettings = JSON.parse(sessionStorage.getItem(`siteSettings_${pageId}`) || sessionStorage.getItem(`sitesettings`));

                if (cachedSiteSettings) {
                    setSiteSettings(cachedSiteSettings);
                } else {
                    // Make the second request to get site settings with the page ID
                    fetch(`${process.env.REACT_APP_BASE_URL}apis/sitesettings?pageid=${pageId}&clearcache=true`)
                        .then(response => response.json())
                        .then(data => {
                            setSiteSettings(data);
                            sessionStorage.setItem(`siteSettings_${pageId}`, JSON.stringify(data));
                        })
                        .catch(error => {
                            console.error('Error fetching data:', error);
                            return <div>Error fetching data</div>;
                        });
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                return <div>Error fetching data</div>;
            });
    };


    const customCSS = siteSettings && siteSettings.HeaderSettings && siteSettings.HeaderSettings.CustomCSSWithinSite
        ? siteSettings.HeaderSettings.CustomCSSWithinSite
        : '';

    return (
        <>
            <CustomStyleComponent css={customCSS} />
            <SiteSettingsContext.Provider value={siteSettings}>
                <TemplateContext.Provider value={template}>
                    <PageIdContext.Provider value={pageId}>
                        <CurrencyContext.Provider value={[currency, setCurrency]}>
                            <GetSiteDataContext.Provider value={getSiteData}>
                                {children}
                            </GetSiteDataContext.Provider>
                        </CurrencyContext.Provider>
                    </PageIdContext.Provider>
                </TemplateContext.Provider>
            </SiteSettingsContext.Provider>
        </>
    );
}

function CustomStyleComponent({ css }) {
    return <style dangerouslySetInnerHTML={{ __html: css }} />
}

export { SiteSettingsContext, TemplateContext, SiteSettingsProvider, PageIdContext, GetSiteDataContext, CurrencyContext };