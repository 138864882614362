import React, { useState } from 'react';
import WidgetWrapper from 'components/WidgetsList/WidgetWrapper/WidgetWrapper';
import './TextBlockWidget.scss';

const TextBlockWidget = ({ data }) => {
    const [readMore, setReadMore] = useState(!data.Content);
    const TitleTag = data.TitleH1Tag || "h2";
    const SubTitleTag = data.SubTitleTag || "h3";

    const handleReadMoreClick = () => {
        setReadMore(!readMore);
    }


    if (data.WidgetId === '1134' || data.WidgetId === 1134) {
        return (<WidgetWrapper data={data}>
            <div className="lg:tw-grid lg:tw-grid lg:tw-grid-cols-12 lg:tw-gap-4 ">
                <div className="tw-col-span-3 lg:tw-mr-16 ">
                    {data.Title && <TitleTag className="tw-text-primary tw-text-3xl tw-font-extralight">{data.Title}</TitleTag>}
                </div>
                <div className="tw-col-span-9 lg:tw-ml-12">
                    {data.Content && <div dangerouslySetInnerHTML={{ __html: data.Content }} />}

                    {data.ReadMore &&
                        <>
                            <div style={{ display: readMore ? 'block' : 'none' }}
                                dangerouslySetInnerHTML={{ __html: data.ReadMore }} />
                            {data.Content && <button onClick={handleReadMoreClick}
                                className="tw-mb-4 tw-text-primary tw-border-b tw-border-primary">
                                {readMore ? data.Dictionaries.HideText : 'Click here to read more >>'}
                            </button>}
                        </>
                    }
                </div>
            </div>


            {/* {data.SubTitle && <SubTitleTag>{data.SubTitle}</SubTitleTag>} */}
        </WidgetWrapper>);
    }

    return (
        <WidgetWrapper data={data}>
            <div className="">
                <div className=" ">
                    {data.Title && <TitleTag className="tw-text-primary tw-text-3xl tw-font-extralight">{data.Title}</TitleTag>}
                </div>
                <div className="">
                    {data.Content && <div dangerouslySetInnerHTML={{ __html: data.Content }} />}

                    {data.ReadMore &&
                        <>
                            <div style={{ display: readMore ? 'block' : 'none' }}
                                dangerouslySetInnerHTML={{ __html: data.ReadMore }} />
                            {data.Content && <button onClick={handleReadMoreClick}
                                className="tw-mb-4 tw-text-primary tw-border-b tw-border-primary">
                                {readMore ? data.Dictionaries.HideText : 'Click here to read more >>'}
                            </button>}
                        </>
                    }
                </div>
            </div>
            

            {/* {data.SubTitle && <SubTitleTag>{data.SubTitle}</SubTitleTag>} */}
        </WidgetWrapper>
    );
};

export default TextBlockWidget;