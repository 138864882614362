import React, { useState, useEffect } from 'react';
import  './Guests.css';
import DropDownPanel from '../../DropDownPanel/DropDownPanel';
import GuestsCounter from './GuestsCounter/GuestsCounter';

const Guests = ({ changeAdults, changChildren, changInfants, enableGuestFilter, clearField, settings }) => {
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [infants, setInfants] = useState(0);
  const [total, setTotal] = useState(1);

  const [maxGuest] = useState(20);
 

  function adultsHasChanged(val){
    setAdults(val);
    changeAdults(val);
  }

  function childrenHasChanged(val) {
    setChildren(val);
    changChildren(val);
  }

  function infantsHasChanged(val) {
    setInfants(val);
    changInfants(val);
  }

  useEffect(() => {
    setTotal(parseInt(adults) + parseInt(children) + parseInt(infants));
  }, [adults, children, infants])

  useEffect(() => {
    if (clearField >0) {
      setAdults(1);
      setChildren(0);
      setInfants(0);
      setTotal(1);
      changeAdults();
      changChildren();
      changInfants();
    }
  }, [clearField])

  if(!enableGuestFilter){
    changeAdults(null);
    changChildren(null);
    changInfants(null);
  }

 
  return (
    <>
      {
       enableGuestFilter &&
        <div className={'guest-filed '}>
            <span className={'tw-absolute tw-top-[5px] tw-left-[6px] tw-text-xs tw-text-primary'}>WHO
            </span>
            <DropDownPanel title={total + " " + (total === 1 ? 'Guest' : 'Guests')}>
              <GuestsCounter name="Adults" min='1' max={maxGuest} initial={adults} upCount={adultsHasChanged} settings={settings}  />
              <GuestsCounter name="children" max={maxGuest} initial={children} upCount={childrenHasChanged} settings={settings} />
              <GuestsCounter name="Infants" max={maxGuest} initial={infants} upCount={infantsHasChanged} settings={settings}  />
          </DropDownPanel >
        </div>
      }
    </>
  );
};

Guests.propTypes = {};

Guests.defaultProps = {};

export default Guests;
