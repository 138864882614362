import React, {useState, useContext } from 'react';
import { FaBed, FaCouch } from 'react-icons/fa'; // Import sample icons
import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import './amenities.css';

const Amenities = ({ propertyAttributes, displayMode = 'table', isLoading }) => {

    const [isTableExpanded, setTableExpanded] = useState(false);

    const toggleTable = () => {
        setTableExpanded(!isTableExpanded);
    };

    const tableStyle = isTableExpanded
        ? {}
        : { maxHeight: '600px', overflow: 'hidden' };

    if (isLoading) {
        return (
            <div className="reviews-container">
                <h4>Amenities</h4>
                <Skeleton count={10} height={15} />
            </div>
        );
    }


    const renderContent = () => {
        if (displayMode === 'table') {
            return propertyAttributes.Categories.map(category => {
                const optionMap = category.Options.reduce((map, option) => {
                    map[option.Id] = option.Name;
                    return map;
                }, {});

                if (!category.SelectedOptions || category.SelectedOptions.length === 0) {
                    return null;
                }

                return (
                    <tr key={category.Id} className="tw-relative">
                        <td className="tw-pr-2 name tw-align-middle">
                            <div className="tw-py-3 tw-font-medium">{category.Name}</div>
                        </td>
                        <td className="tw-py-3 tw-pr-2">
                            <div className="tw-py-3  tw-grid  tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-4">
                                {category.SelectedOptions.map((id, index) => (
                                    <span className="bullet-item">
                                        {optionMap[id]}
                                        {index !== category.SelectedOptions.length - 1 && " "}
                                    </span>
                                ))}
                            </div>
                        </td>
                    </tr>
                );
            });
        } else {
            let prevColSpan = 1;
            return propertyAttributes.Categories.map(category => {
                const optionMap = category.Options.reduce((map, option) => {
                    map[option.Id] = option.Name;
                    return map;
                }, {});

                if (!category.SelectedOptions || category.SelectedOptions.length === 0) {
                    return null;
                }
                // Determine the column span for each category based on its number of selected options
                const optionsCount = category.SelectedOptions.length;
                let colSpan = 1; // default to 1

                if (optionsCount > 30) {
                    colSpan = 4;
                } else if (optionsCount > 20) {
                    colSpan = 3;
                } else if (optionsCount > 10) {
                    colSpan = 2;
                }


                // If the previous category also spanned 2 columns, set the current one to span 3
                if (prevColSpan === 2 && colSpan === 2) {
                    colSpan = 3;
                }

                // Update prevColSpan for next iteration
                prevColSpan = colSpan;

                const divClass = `tw-p-4 tw-border tw-border-gray-200 tw-rounded tw-shadow-sm tw-bg-white tw-col-span-${colSpan}`;

                let ulClass = "tw-p-0 tw-m-0";
                if (category.SelectedOptions.length > 20) {
                    ulClass = "tw-p-0 tw-m-0 tw-grid tw-grid-cols-3 tw-gap-4";
                } else if (category.SelectedOptions.length > 6) {
                    ulClass = "tw-p-0 tw-m-0 tw-grid tw-grid-cols-2 tw-gap-x-4";
                }
                return (
                    <div key={category.Id} className={divClass}>
                        <div className="tw-flex tw-items-center tw-justify-between">
                            <h4 className="tw-text-lg tw-font-semibold">{category.Name}</h4>
                        </div>
                        <ul className={ulClass}>
                            {category.SelectedOptions.map(id => (
                                <li key={id}>
                                    <span className="tw-mr-3"><FontAwesomeIcon icon={faCheck} /></span>
                                    {optionMap[id]}
                                </li>
                            ))}
                        </ul>
                    </div>
                );
            });
        }
    };

    return (
        <div className="amenities-container">
            <h4>Amenities</h4>
            <div className={displayMode === 'table' ? "tw-w-full tw-auto tw-border-collapse table-wrapper" : "tw-grid md:tw-grid-cols-3 lg:tw-grid-cols-3 tw-gap-4"} style={tableStyle}>
                {!isTableExpanded && <div className="fade-out-table"></div>}
                <table className="table table-striped amenities">
                    <tbody>
                        {propertyAttributes.Categories ? renderContent() : null}
                    </tbody>
                </table>
            </div>
            <button
                onClick={toggleTable}
                className="tw-mb-4 tw-text-primary tw-border-b tw-border-primary">
                {isTableExpanded ? 'Show Less' : 'Show More'}
            </button>
        </div>
    );
};
export default Amenities;