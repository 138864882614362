import React from 'react';
import Skeleton from 'react-loading-skeleton';
import './rates.css';

const Rates = ({ rates, isLoading }) => {
    if (isLoading) {
        return (
            <div className="reviews-container">
                <h4>Rates</h4>
                <Skeleton count={10} height={15} />
            </div>
        );
    }
 
    return (
        <div>
            <h4>Rates</h4>
            <table className="tw-w-full tw-table-auto tw-border-t-collapse  tw-mt-2 rates-table">
                <thead>
                    <tr className="rates-header">
                        {rates.some(rate => rate.StartDate) && <th className=" tw-px-4 tw-py-2 tw-py-4">Start Date</th>}
                        {rates.some(rate => rate.EndDate) && <th className=" tw-px-4 tw-py-2">End Date</th>}
                        {rates.some(rate => rate.Nightly) && <th className=" tw-px-4 tw-py-2">Nightly</th>}
                        {rates.some(rate => rate.Weekly) && <th className=" tw-px-4 tw-py-2">Weekly</th>}
                        {rates.some(rate => rate.Monthly) && <th className=" tw-px-4 tw-py-2">Monthly</th>}
                        {rates.some(rate => rate.MinStay) && <th className=" tw-px-4 tw-py-2">MinStay</th>}
                    </tr>
                </thead>
                <tbody>
                    {rates.map((rate, index) => (
                        <tr key={index} >
                            {rate.StartDate && <td className="tw-border-t   tw-px-4 tw-py-2 tw-py-4" dangerouslySetInnerHTML={{ __html: rate.StartDate }}></td>}
                            {rate.EndDate && <td className="tw-border-t   tw-px-4 tw-py-2 tw-py-4" dangerouslySetInnerHTML={{ __html: rate.EndDate }}></td>}
                            {rate.Nightly && <td className="tw-border-t   tw-px-4 tw-py-2 tw-py-4" dangerouslySetInnerHTML={{ __html: rate.Nightly }}></td>}
                            {rate.Weekly && <td className="tw-border-t   tw-px-4 tw-py-2 tw-py-4" dangerouslySetInnerHTML={{ __html: rate.Weekly }}></td>}
                            {rate.Monthly && <td className="tw-border-t   tw-px-4 tw-py-2 tw-py-4" dangerouslySetInnerHTML={{ __html: rate.Monthly }}></td>}
                            {rate.MinStay && <td className="tw-border-t   tw-px-4 tw-py-2 tw-py-4">{rate.MinStay}</td>}
                        </tr>
                    ))}
                </tbody>
            </table>
            {(rates && rates.length === 0) && <div>No rates available</div>}
        </div>
    );
};

export default Rates;