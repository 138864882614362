import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import Form from 'react-bootstrap/Form';
import FlexibleNights from 'components/SearchForm/FlexibleNights/FlexibleNights'; // Assuming the FlexibleNights.js file is in the same directory
import './Filters.css';
import useWindowSize from "utils/useWindowSize";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PriceRange from '../PriceRange/PriceRange';
import useGetUrlParams from 'utils/useGetUrlParams';
import { Routes, Route, useParams } from 'react-router-dom';
let arr = [];
let open = false;
 

const Filters = ({ changeNightRange, changeWeekRange, changeAttrsOnValueChange, changeAttrsOnLoad, startSearchFromModal, enablePriceRangeSlider, enabledFilters, attributesList, priceRangeSliderSettings, changePriceMode, clearField, changeFlexability}) => {

  const refsList = useRef([]);
  const { width } = useWindowSize();
  const [showFilters, setShowFilters] = useState(true);

  const [expandedSections, setExpandedSections] = useState({});
  const [checkedOptions, setCheckedOptions] = useState({});

  const [show, setShow] = useState(false);
  const [isCleanedPrice, setIsCleanedPrice] = useState(false);
  const [checkedAttrs, setCheckedAttrs] = useState(null);

  const [priceRangeState, setPriceRangeState] = useState(false);
  const [startedSearch, setStartedSearch] = useState(false);
  

  const [nightlyParamsRange] = useGetUrlParams('NightlyPriceRange');
  const [weeklyParamsRange] = useGetUrlParams('WeeklyPriceRange');
  const [priceFilterType] = useGetUrlParams('PriceFilterType');

 
  let [attributes] = useGetUrlParams('attrs');
  let [attrs] = useGetUrlParams('Attributes');
 


  const [paramsAttributesOpenModal, setParamsAttributesOpenModal] = useState([]);

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };


  const handleClose = () => {
    setShow(false);
  };
 
  const handleShow = (e) => {
    setShow(true);
    setStartedSearch(true);
  };
 
  
  useEffect(() => {
    if (clearField >0) {
      handleReset();
    }
  }, [clearField]);

  useEffect(() => {
    
    if (width <  window.md) {
 
       setShowFilters(false);
    }else{
      setShowFilters(true);
    }
  }, [width]);

  
  useEffect(() => {

    if (nightlyParamsRange !== null) {

      if (nightlyParamsRange !== '') {
        changeNightRange(nightlyParamsRange);
      }
      if (nightlyParamsRange === '') {
        changeNightRange('');
      }
    }

    if (weeklyParamsRange !== null) {
  
      if (weeklyParamsRange !== '') {

        changeWeekRange(weeklyParamsRange);
      }
      if (weeklyParamsRange === '') {
        changeWeekRange('');
      }
    }
    
  }, [priceRangeState]);

  useEffect(() => {
    if (priceFilterType !== null) {
      if (priceFilterType === 'Weekly') {
        changePriceMode("Weekly");
      }
      if (priceFilterType === 'Nightly') {
        changePriceMode("Nightly");
      }
      if (priceFilterType === '') {
        changePriceMode("");
      }
    }

  }, [priceFilterType]);


  useEffect(() => {
    // On open modal Getting url "Attributes"  params if exist and making it checked 
 
       
    if ( paramsAttributesOpenModal.length === 0 && attrs !== null) {
        setParamsAttributesOpenModal(attrs.toString());
    }
    
 
    if (!paramsAttributesOpenModal && attrs !== null ) {
      setParamsAttributesOpenModal(attrs);
    }
    if (!paramsAttributesOpenModal && attributes !== null ) {
      setParamsAttributesOpenModal(attributes)
    }
    
    if (!open) {
      if (paramsAttributesOpenModal) {
        let attrArr;
        if (Array.isArray(paramsAttributesOpenModal)){
          attrArr = paramsAttributesOpenModal;
        }
        if (typeof paramsAttributesOpenModal === 'string'){
          attrArr = paramsAttributesOpenModal.split(','); 
        }
          
        for (let i = 0; i < refsList.current.length; i++) {
          if (typeof refsList.current[i] != 'undefined' && refsList.current[i] != null) {
            if (refsList.current[i].tagName === 'INPUT') {
              if (attrArr.includes(refsList.current[i].id)) {
                refsList.current[i].checked = true;
              }
            }
            if (refsList.current[i].tagName === 'SELECT') {
              for (let a = 0; a < refsList.current[i].length; a++) {
                const element = refsList.current[i][a];
                if (attrArr.includes(element.value)) {
                  refsList.current[i].selectedIndex = a;
                }
              }
            }
          }
        }
      }
 
    }
    // On open modal Getting url "attr"  params if exist and making it checked


    setPriceRangeState(true); // init PriceRange Slider

  }, [handleShow]);
 
  useEffect(() => {
    //Showing the number of checked filters on the label
 
    if (attributes || attrs) {
 
      if (attributes.length > 0) {
        let quantity;
        
        if (Array.isArray(attributes)) {
            quantity = attributes.filter(number => number !== "All");
          
        }
        if (typeof attributes === 'string') {
          quantity = attributes.split(',');
        }
        setCheckedAttrs(quantity.length);
        changeAttrsOnLoad(attributes);
      }
      if (attrs){
        let quantity = attrs.split(',').filter(number => number !== "All");
        setCheckedAttrs(quantity.length);
        changeAttrsOnLoad(attrs.toString());
      }
    }

    if (attributes === '' && attrs === ''  ) {
      changeAttrsOnLoad('');
    }
    
  }, [attributes, attrs]);
 
  if (!enablePriceRangeSlider && !enabledFilters) {
    return false;
  }

  let attrsList = [];

  function isObjectEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  const toggleSection = (elementId) => {
    
    setExpandedSections(prevState => {
      const newState = { ...prevState };
      console.log(newState, elementId);
      if (newState[elementId] === undefined && (elementId === 'element-0' || elementId === 'element-1' || elementId === 'element-2')) {
        
        newState[elementId] = false;
        return newState;
      }
      
      
      newState[elementId] = !newState[elementId];
      return newState;
    });
  };

  const handleChange = (e, elementId, optionId) => {
    
    if (optionId) {
      setCheckedOptions(prev => ({ ...prev, [elementId]: optionId }));
    }
    //Getting all checked options on click
    for (let i = 0; i < refsList.current.length; i++) {
      if (refsList.current[i] != null && typeof refsList.current[i] != "undefined") {
        if (refsList.current[i].tagName === 'INPUT') {
          if (refsList.current[i].checked) {
            arr.push(refsList.current[i].id);
          }
        }
      }
    }
    for (let i = 0; i < refsList.current.length; i++) {
      if (refsList.current[i] != null && typeof refsList.current[i] != "undefined") {
        if (refsList.current[i].tagName === 'SELECT') {
          if (refsList.current[i].value !== "" && refsList.current[i].selectedIndex !== 0) {
            arr.push(refsList.current[i].value);
          }
        }
      }
    }
    
    if (arr.length > 0) {
      changeAttrsOnValueChange(arr.toString());
      setParamsAttributesOpenModal(arr);
    }
    if (arr.length === 0) {
      changeAttrsOnValueChange('');
      setParamsAttributesOpenModal([]);
    }
    setCheckedAttrs(arr.length);
    arr = [];
  }

  const startSearch = () => {
    startSearchFromModal();
    handleClose();
  }



  const handleReset = () => {
    setIsCleanedPrice(!isCleanedPrice); //Sending signal to Price Range need reset
    for (let i = 0; i < refsList.current.length; i++) {
      if (typeof refsList.current[i] != 'undefined') {
        if (refsList.current[i]?.hasOwnProperty('checked')) {
          if (refsList.current[i].checked) {
            refsList.current[i].checked = false;
          }
        }
        if (refsList.current[i]?.tagName === 'SELECT') {
          refsList.current[i].selectedIndex = 0;
        }
      }
    }
    
    changeAttrsOnValueChange('');
    setParamsAttributesOpenModal([]);
    setCheckedAttrs([]);
    changeNightRange('');
    changeWeekRange('');
  }



  //Building template 
 
  if (enabledFilters && attributesList) {
 
  
    attrsList = attributesList.map(function (element, index) {
       
      const elementId = `element-${index}`;

      const defaultExpanded = ["Bedrooms", "Theme", "Pool Type", "Property Type" ].includes(element.Name);
      const isExpanded = expandedSections[elementId] !== undefined ? expandedSections[elementId] : defaultExpanded;
      const isOptionChecked = checkedOptions[elementId] || false;
      let template = element.IsCheckBox ? (
        <div className="tw-grid lg:tw-grid-cols-1">
          {element.Options.map((option, i) => (
            <label className="tw-mr-5" key={i}>
              <input className="form-check-input" ref={(el) => (refsList.current[option.Id] = el)} id={option.Id} type="checkbox" onChange={handleChange} />
              <span className="tw-pl-2">{option.Name}</span>
            </label>
          ))}
        </div>
      ) : (
        <Form.Select className="tw-max-w-[300px]" ref={(el) => (refsList.current[index] = el)} name={element.Alias} onChange={handleChange}>
          {!element.HideOptionAny && <option>Any</option>}
          {element.Options.map((option, i) => (
            <option value={option.Id} key={i}>
              {option.Name}
            </option>
          ))}
        </Form.Select>
      );



      if (element.IsCheckBox === false) {
        let optionList = element.Options.map(function (option, i) {

          return <option value={option.Id} key={i}>{option.Name}</option>
        });
        let anyOption = '';
        if (!element.HideOptionAny) {
          anyOption = <option>Any</option>
        }

        template = <Form.Select className="tw-max-w-[300px]" ref={element => (refsList.current[index] = element)} name={element.Alias} onChange={handleChange}  >
          {(anyOption !== '') ? anyOption : null}
          {optionList}
        </Form.Select>;
      } else {
        let checkBoxList = element.Options.map(function (option, i) {
          return <label className="tw-mr-5" key={i}>
            <input className="form-check-input" ref={element => (refsList.current[option.Id] = element)} id={option.Id} type="checkbox" onChange={handleChange} />
            <span className="tw-pl-2">{option.Name}</span>
          </label>;
        });
        template = <div className="tw-grid lg:tw-grid-cols-1">{checkBoxList}</div>
      }
      return (
        <div key={index} className="tw-py-2">
          <div className="tw-flex tw-justify-between tw-items-center" onClick={() => toggleSection(elementId)}>
            <h6 className="tw-text-md tw-font-medium tw-mt-3 tw-uppercase">{element.Name}</h6>
            <div
              className={`tw-w-6 tw-h-6 tw-rounded-full tw-flex tw-items-center tw-justify-center filter-switch tw-cursor-pointer ${isExpanded || isOptionChecked ? "tw-bg-white tw-border-2 tw-border-primary opened-filter" : "tw-bg-primary closed-filter"
                }`}
            ></div>
          </div>
          {(isExpanded || isOptionChecked) && template}
        </div>
      );
    });
  }

  return (
    <>
      <button
        className="tw-bg-primary tw-text-white tw-px-5 tw-py-1 tw-rounded tw-shadow-md tw-block md:tw-hidden"
        onClick={toggleFilters}>
        <FontAwesomeIcon icon={faFilter} /> Filters
      </button>
      {showFilters && ( 
        <div className="Filters sm:tw-h-[95vh] tw-overflow-y-auto tw-w-full tw-pb-10  xl:tw-pr-4" >
          <span className="field tw-cursor-pointer tw-flex tw-items-center" variant="default" onClick={handleShow} >
 

            <h5 className="tw-text-primary tw-text-lg tw-font-light tw-mb-0 tw-mt-5 md:tw-mt-0">FIND A VILLA
              {checkedAttrs > 0 ? <span className="tw-ml-2 badge rounded-pill bg-primary">
                {checkedAttrs}
              </span> : null}
            </h5>   
          </span>
           

          <FlexibleNights 
            changeFlexability={changeFlexability}
          />  

          {enablePriceRangeSlider &&
            <PriceRange
              changePriceMode={changePriceMode}
              priceRangeSliderSettings={priceRangeSliderSettings}
              changeNightRange={changeNightRange}
              changeWeekRange={changeWeekRange}
              priceRangeState={priceRangeState}
              resetPrice={isCleanedPrice}
              OpenedModal={startedSearch}
            />}

          {enabledFilters && attrsList}
          {/* <Button variant="primary" size="sm" onClick={startSearch} className="text-white md:tw-hidden tw-mr-3">
            Apply
          </Button>   */}

          {
            (checkedAttrs > 0) && <Button variant="primary" size="sm" onClick={handleReset} className="text-white">
              Reset
            </Button>
          }

 
        </div>
      )}

    </>


  );
};


export default Filters;
