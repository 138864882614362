import React, { useEffect, useState, useContext } from 'react';
import { DayPickerRangeController } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import styles from './AvailabilityCalendar.scss';
import useWindowSize from "utils/useWindowSize";
import CalendarDataContext from '../../../contexts/CalendarDataContext';
import moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
import Skeleton from 'react-loading-skeleton';
import { parseISO, isBefore } from 'date-fns';
import { Tooltip } from 'react-tooltip';
import { css, StyleSheet } from 'aphrodite';
import './availabilityCalendar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
const momentRange = extendMoment(moment);


const AvailabilityCalendar = ({ selectedDates, onDateChange, isLoading, disableAvailability }) => {
    const CalendarData = useContext(CalendarDataContext);
    const windowSize = useWindowSize();
    const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });
    const [showTooltip, setShowTooltip] = useState(false);
 
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [focusedInput, setFocusedInput] = useState('startDate');
    const [calendarCols, setCalendarCols] = useState(3);
    const [loading, setLoading] = useState(true);
    const [reset, setReset] = useState(false);
 
    const styles = StyleSheet.create({
        day: {
            ':hover': {
                backgroundColor: 'gray'
            },
        },
        dayBlocked: {
            ':hover': {
                backgroundColor: 'inherit',
            },
        },
    });

    useEffect(() => {
        if (CalendarData && CalendarData.length > 0) {
            setLoading(false);
        }
    }, [CalendarData]);

    useEffect(() => {
        if (windowSize.width > 1500) {
            setCalendarCols(3);
        }
        if (windowSize.width < 1500 && windowSize.width > 800) {
            setCalendarCols(2);
        }
        if (windowSize.width < 800) {
            setCalendarCols(1);
        }
    }, [windowSize.width]);

    const handleDatesChange = ({ startDate: newStartDate, endDate: newEndDate }) => {
        if (disableAvailability) {
            setStartDate(newStartDate);
            setEndDate(newEndDate);
            onDateChange(newStartDate, newEndDate);
            setFocusedInput(focusedInput === 'startDate' ? 'endDate' : 'startDate');
            return;
        }

        let selectedBookedDateInRange = hasSelectedBookedDateInRange(newStartDate || newEndDate);
        if (selectedBookedDateInRange) {
            setStartDate(null);
            setEndDate(null);
            setFocusedInput('startDate');
            return;
        }

        if (startDate && newStartDate && endDate && newEndDate) {
            if (newStartDate.isAfter(startDate) && newStartDate.isBefore(endDate)){
                setStartDate(newStartDate);
                setEndDate(null);
                setFocusedInput('startDate');
                return;
            }
            if (newStartDate.isBefore(startDate)){
                setStartDate(newStartDate);
                setEndDate(null);
                setFocusedInput('startDate');
                return;
            }
            if (newEndDate.isAfter(endDate)){
                setStartDate(newEndDate);
                setEndDate(null);
                setFocusedInput('startDate');
                return;
            }
        }


        setStartDate(newStartDate);
        setEndDate(newEndDate);
        onDateChange(newStartDate, newEndDate);
        setFocusedInput(focusedInput === 'startDate' ? 'endDate' : 'startDate');
    };

  
    const handleFocusChange = (focusedInput) => {
        setFocusedInput(focusedInput);
        if (startDate && endDate) {
            setReset(true);
        }
    }
 
    // Add this new function to get the minStay for a given date
    const getMinStayForDate = (date) => {
        const dateString = date ? date.format('DD/MM/YYYY') : null;
        const dateObject = dateString ? CalendarData.find(({ t }) => t.startsWith(dateString)) : null;

        // Add a check for undefined or null values
        return dateObject && dateObject.ms ? dateObject.ms : '1+';
    };

    const handleClearDates = () => {
        setStartDate(null);
        setEndDate(null);
        setFocusedInput('startDate');
    };

    const hasSelectedBookedDateInRange = (day) => {
        let date = startDate || day;
        let minStay = getMinStayForDate(date);
        
        let potentialEndDate = addMinStayToStartDate(date, minStay);
   
        const satisfiesCondition = !hasBookedDateInRange(date, potentialEndDate);
        return !satisfiesCondition;
    }

    const isBlocked = day => {
        const dayInUTC = day.utc();
        if (disableAvailability){
            if (day < new Date()) {
                return true;
            }
            return false;
        }
       
        const startDateInUTC = startDate ? startDate.utc() : null;

        const dateString = dayInUTC.format('DD/MM/YYYY');
        const currentDateObject = CalendarData.find(({ t }) => t.startsWith(dateString));
        const startDateString = startDateInUTC ? startDateInUTC.format('DD/MM/YYYY') : null;
        const startDateObject = startDateString ? CalendarData.find(({ t }) => t.startsWith(startDateString)) : null;

        let selectedBookedDateInRange = hasSelectedBookedDateInRange();

        if (currentDateObject && !currentDateObject.e) {
            return true;
        }

        if (startDateObject) {
            if (hasBookedDateInRange(startDateInUTC, dayInUTC) && startDateInUTC && !endDate) {
                return true;
            }
        }

        // Blocking all dates before today
        if (dayInUTC.isBefore(moment.utc(), 'day')) {
            return true;
        }

        // Blocking all dates before the selected start date
        if (startDateInUTC && dayInUTC.isBefore(startDateInUTC, 'day') && !endDate) {
            return true;
        }

        if (startDateInUTC && endDate) {
            return !(currentDateObject?.cs || currentDateObject?.sbs);
        }

        // If a start date is selected, use the rules for the selected date
        if (startDateInUTC && startDateObject) {
            // startDate is the same as the current day
            if (startDateInUTC.isSame(dayInUTC, 'day')) {
                return false;
            }

            // startDate is a 'cs', so we look for 'ce' after this date
            if (startDateObject.cs && dayInUTC.isAfter(startDateInUTC, 'day')) {
                if (currentDateObject && currentDateObject.ce && !hasBookedDateInRange(startDateInUTC, dayInUTC)) {
                    const nightDifference = dayInUTC.diff(startDateInUTC, 'days') - 1;

                    if (isWithinMinStay(startDateObject.ms, nightDifference)) {
                        return false; // date is 'ce' and no booked dates in between
                    }
                }
            }
            // startDate is a 'sbs', so we look for 'sbe' within 6 nights after this date
            if (startDateObject.sbs && dayInUTC.isAfter(startDateInUTC, 'day') && dayInUTC.diff(startDateInUTC, 'days') < 7) {
                if (currentDateObject && currentDateObject.sbe && !hasBookedDateInRange(startDateInUTC, dayInUTC)) {
                    const nightDifference = dayInUTC.diff(startDateInUTC, 'days') - 1;
                    if (isWithinMinStay(startDateObject.ms, nightDifference)) {
                        return false; // date is 'ce' and no booked dates in between
                    }
                }
            }
            return true;
        }

        // Default behavior when no start date is selected
        if (!currentDateObject || !currentDateObject.e) {
            return true;
        }
        return !(currentDateObject.cs || currentDateObject.sbs);
    };
 
    const hasBookedDateInRange = _.memoize((startDate, endDates) => {
        if (!startDate || !endDates) {
            // If either date is null, we cannot create a range
            return false;
        }

        // Ensure endDates is always an array
        if (!Array.isArray(endDates)) {
            endDates = [endDates];
        }

        // We only check against the first endDate
        const endDate = endDates[0];
        const range = startDate.isBefore(endDate, 'day') ? momentRange.range(startDate, endDate) : momentRange.range(endDate, startDate);
        return Array.from(range.by('days')).some(dateInRange => {
            const dateInRangeString = dateInRange.format('DD/MM/YYYY');
            const dateInRangeObject = CalendarData.find(({ t }) => t.startsWith(dateInRangeString));
            return dateInRangeObject ? !dateInRangeObject.e : false;
        });
    });

    const isWithinMinStay = (minStay, nightDifference) => {
        if (!minStay) {
            console.warn('Invalid minStay value: ', minStay);
            return false;
        }
   
        if (typeof minStay === 'string') {
            if (minStay.includes('+')) {
                const minStayNumber = parseInt(minStay.replace('+', ''), 10);
                return nightDifference + 1 >= minStayNumber; // Include the start date
            } else {
                const availableStays = minStay.split(',').map(Number);
                return availableStays.includes(nightDifference + 1); // Include the start date
            }
        }
        
        else if (typeof minStay === 'number') { // Check if it's a single number
            
            return nightDifference + 1 === minStay; // Include the start date
        }
        console.error('Invalid minStay value: ', minStay);
        return true;
    };

    const addMinStayToStartDate = (startDate, minStay) => {
        
        const date = moment(startDate);
        if (!minStay) {
            console.warn('Invalid minStay value: ', minStay);
            return null;
        }
        if (typeof minStay === 'string') {
            if (minStay.includes('+')) {
                const minStayNumber = parseInt(minStay.replace('+', ''), 10);
                return date.clone().add(minStayNumber, 'days');
            } else if (minStay.includes(',')) {
                const minStayNumbers = minStay.split(',').map(Number);
                return minStayNumbers.map(num => date.clone().add(num, 'days'));
            }
        } else if (typeof minStay === 'number') {
            return date.clone().add(minStay, 'days');
        }

        console.error('Invalid minStay value: ', minStay);
        return null;
    };
 
 

    const renderDayContents = day => {
        if (disableAvailability) {
            return (
                <>
                    {day.format('D')}
                </>
            );
        }
        let classNames = '';

        const dateString = day.format('DD/MM/YYYY');
        const currentDateObject = CalendarData.find(({ t }) => t.startsWith(dateString));

        const startDateString = startDate ? startDate.format('DD/MM/YYYY') : null;
        const startDateObject = startDateString ? CalendarData.find(({ t }) => t.startsWith(startDateString)) : null;

        let tooltipText = '';

        const isSelectedDate = dateString === startDateString;
        const selectedBookedDateInRange = hasSelectedBookedDateInRange();
 

        if (currentDateObject ) {


           
            if (selectedBookedDateInRange){
               classNames += ' no-hover';
            }
            const newStartDate = moment(day);
            const potentialEndDate = addMinStayToStartDate(newStartDate, currentDateObject.ms);

            const satisfiesCondition = !hasBookedDateInRange(newStartDate, potentialEndDate);

            if (typeof currentDateObject.ms === 'string' && currentDateObject.e) {
                
                const minStayNumber = parseInt(currentDateObject.ms.replace('+', ''), 10);
        
                tooltipText = satisfiesCondition ? "" : `${minStayNumber} nights minimum`;
                if(!satisfiesCondition){
                    setShowTooltip(true)
                }

            } else {
                if (currentDateObject.e){
                    tooltipText = satisfiesCondition ? "" : "Nights minimum";
                }
                
            }

            if (!currentDateObject.cs && !currentDateObject.sbs){
                tooltipText = 'Not a changeover day';
            }
            if (!currentDateObject.e ) {
                tooltipText = 'Booked';
                classNames += ' booked';
            }
            if (currentDateObject.e && currentDateObject.cs || currentDateObject.sbs && satisfiesCondition) {
             //   tooltipText = 'Avaliable as a start day';
            }
        }
 
        if (currentDateObject?.sbs && !currentDateObject?.cs && startDate && !endDate && day.diff(startDate, 'days')  === 7) {
             classNames += 'disabledDay ';
        }



        return (
            <div className={`${classNames} tw-relative`}
                // onMouseMove={handleMouseMove}
                // onMouseLeave={handleMouseLeave}
                //data-tip={tooltipText}
            >
                {day.format('D')}
                {(showTooltip && ((startDate && endDate && tooltipText.length > 0) || (!startDate && !endDate) && tooltipText && tooltipText.length > 0)) &&
                    <div className='calendar_tooltipText'
                        style={{
                            position: 'absolute',
                            left: `${cursorPos.x - 60}px`,
                            top: `${cursorPos.y + 25}px`
                        }}
                    >
                        {tooltipText}
                    </div>
                }
            </div>
        );
    };


    if (isLoading) {
        return (
            <div>
                <h4>Availability Calendar</h4>
                <Skeleton height={400} />
            </div>
        );
    }
    
    return loading ? (
        <div>Loading...</div>
    ) : (
            <div className={`${styles.availabilityCalendar}`}>

                <h4 className="tw-text-xl tw-mb-4">Availability Calendar</h4>
                <div className={'availabilityCalendar'}>
                  
                    <DayPickerRangeController

                        navPrev={<span class="calendar-arrows left-arrow"><FontAwesomeIcon icon={faAngleLeft} /></span>}
                        navNext={<span class="calendar-arrows right-arrow"><FontAwesomeIcon icon={faAngleRight} /></span>}

                        startDate={startDate}
                        endDate={endDate}

                        onDatesChange={handleDatesChange}
                        focusedInput={focusedInput}
                        onFocusChange={handleFocusChange}
                        numberOfMonths={calendarCols}
                        isOutsideRange={isBlocked}
                        daySize={42}
                        maxDate={false}
                        showClearDates
                        block

                        keepOpenOnDateSelect
                        renderCalendarInfo={() => <div />}
                        calendarInfoPosition="top"
                        hideKeyboardShortcutsPanel
                        renderDayContents={renderDayContents}

                        transitionDuration={90}
                    />
                </div>

                <button
                    className="tw-mb-4 tw-text-primary tw-border-b tw-border-primary tw-mt-2"
                    onClick={handleClearDates}
                >
                    Clear Dates
                </button>
            </div>
    );

 
};

export default AvailabilityCalendar;

 