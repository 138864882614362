import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import Lightbox from 'react-image-lightbox';
import 'swiper/swiper.min.css';
import 'react-image-lightbox/style.css';
import './ImageSlider.css';
import Skeleton from 'react-loading-skeleton';
import useWindowSize from "utils/useWindowSize";
SwiperCore.use([Navigation]);

const ImageSlider = ({ images, isLoading }) => {
    const [photoIndex, setPhotoIndex] = useState(Math.floor(images.length / 2));
    const [isOpen, setIsOpen] = useState(false);
    const [loadedImages, setLoadedImages] = useState([]);
    const [showSlider, setShowSlider] = useState(false);
    let [height, setHeight] = useState(600);
    const windowSize = useWindowSize();

    useEffect(() => {
        if (windowSize.width < 640) {
            setHeight(300);
        } else {  
            setHeight(600);
        }  
    }, [windowSize]);
 


    useEffect(() => {
        const loadImages = async () => {
            const promises = images.map((image) => {
                return new Promise((resolve, reject) => {
                    const img = new Image();
                    img.src = `${image.Url}?width=1000&height=600&scale=both&mode=crop`;
                    img.alt = `${image.Alt}`;
                    img.onload = () => resolve(image.Url);
                    img.onerror = reject;
                });
            });

            try {
                const loaded = await Promise.all(promises);
                setLoadedImages(loaded);
            } catch (error) {
                console.error('One or more images failed to load', error);
            }
        };

        loadImages();
    }, [images]);

    useEffect(() => {
        setTimeout(() => {
            setShowSlider(true);
        }, 1300);
    }, [isLoading]);


    const handleSlideClick = (index) => {
        setPhotoIndex(index);
        setIsOpen(true);
    };

    const handleViewAllClick = () => {
        setPhotoIndex(0);
        setIsOpen(true);
    };

 
    if (isLoading ){
        return(
            <div className="tw-w-full tw-overflow-hidden">
                <div className="tw-bg-slate-200 tw-h-[600px]" >
                    
                </div>
            </div>
        );
    }

    return (
        <div className={`tw-relative  ${showSlider ? '' : 'tw-bg-slate-200'}`}>
            <div className={` ${showSlider ? 'opacity-100' : 'opacity-0'}`}>
                {(images.length) && (
                    <Swiper

                        key={loadedImages.join('_')}
          
                        spaceBetween={15}
                        centeredSlides={true}
                        loop={true}
                        navigation={{ nextEl: '.swiper-button-next ', prevEl: '.swiper-button-prev' }} 
                        breakpoints={{
                            // when window width is >= 640px
                            640: {
                                slidesPerView: 1.5,
                            },
                            // when window width is <= 639px
                            0: {
                                slidesPerView: 1,
                            }
                        }}
                    >
                        {images.map((image, index) => (
                            <SwiperSlide key={image.Id} style={{ padding: '0 2px' }}>
                                <div
                                    style={{
                                        backgroundImage: `url(${image.Url})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        height: height + 'px',
                                        width: '100%',
                                        borderRadius: '1px' // optional, for styling
                                    }}
                                    onClick={() => handleSlideClick(index)}
                                >
                                </div>
                            </SwiperSlide>
                        ))}
                        <div className="swiper-button-next bg tw-z-20"></div>
                        <div className="swiper-button-prev bg tw-z-20"></div>
                    </Swiper>
                )}

                <button onClick={handleViewAllClick} className="view-all-photos !tw-font-medium">View all photos ({images.length})</button>  

                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex % images.length].Url}
                        nextSrc={images[(photoIndex + 1) % images.length].Url}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length].Url}
                        onCloseRequest={() => setIsOpen(false)}
                        onMovePrevRequest={() =>
                            setPhotoIndex((photoIndex + images.length - 1) % images.length)
                        }
                        onMoveNextRequest={() =>
                            setPhotoIndex((photoIndex + 1) % images.length)
                        }
                    />
                )}
            </div>
        </div>
    );
};

export default ImageSlider;