import React, { useEffect, useState, useRef } from 'react';
import styles from './SearchResultMap.module.css';

import MapPoint from './MapPoint/MapPoint';
import OverlayContainer from "./OverlayContainer/OverlayContainer";

const SearchResultMap = (props) => {
  const ref = useRef(null);
  const [map, setMap] = useState();

  const [markersData, setMarkersData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Define center and zoom properties for Algarve.
  const algarveCenter = { lat: 37.0145374, lng: -8.00716 };  // Approximate center of Algarve (near Albufeira)
  const algarveZoom =11;  // Adjust as needed based on the specific view you want.

  const blackWhiteStyles = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dadada"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#c9c9c9"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    }
  ];

  

  useEffect(() => {
    // Removed the code that calculates bounds based on marker locations.

    if (ref.current) {
      let createdMap = new window.google.maps.Map(
        ref.current,
        {
          center: algarveCenter, // Set to Algarve's center.
          zoom: algarveZoom,     // Set to Algarve's zoom level.
          gestureHandling: 'greedy',
          disableDefaultUI: true,
          clickableIcons: true,
          styles: blackWhiteStyles   
        }
      );
      setMap(createdMap);
      setIsLoading(true);

      // Removed the code that fits bounds based on marker locations.

      setMap(createdMap);
      setMarkersData(props.mapData);
    }
  }, [markersData, props.mapData]);

 

  const markers = markersData.map(function (apartment, index) {
    const { ...info } = apartment;
    return map && (
      <OverlayContainer
        key={index}
        map={map}
        position={{
          lat: apartment.Latitude,
          lng: apartment.Longitude
        }}
      >
        <MapPoint {...info} />
      </OverlayContainer>
    );
  });

  return (
    <div ref={ref} id="map"
      className={styles.SearchResultMap}
      style={{ height: '100vh', width: '100%' }}
    >
      {markers}
    </div>
  );
};

 

export default SearchResultMap;