import React, { useEffect, useState } from 'react';
import PropertyItem from 'components/SearchResult/PropertyItem/PropertyItem';
import SkeletonProperty from 'components/SkeletonProperty/SkeletonProperty';
import ReactPaginate from 'react-paginate';

const PropertiesList = ({ propertyList, displayMode, sendParams, total, isFetching, dictionary, widgetGrid }) => {

    const [gridClasses, setGridClasses] = useState('tw-grid sm:tw-grid-cols-1  xl:tw-grid-cols-1 tw-gap-4');
    const [pageCount, setPageCount] = useState(0);
 
    useEffect(() => {
        setPageCount(Math.ceil(total / 20));
      
    }, [total]);

    useEffect(() => {
 
        if (widgetGrid) {
            
            setGridClasses(widgetGrid);
        }
    }, [widgetGrid]);

    useEffect(() => {
        if (!widgetGrid && propertyList){
            if (displayMode) {
                setGridClasses("tw-grid sm:tw-grid-cols-1  md:tw-grid-cols-1 2xl:tw-grid-cols-1 tw-gap-4")
            } else {
                setGridClasses("tw-grid sm:tw-grid-cols-1  md:tw-grid-cols-1 2xl:tw-grid-cols-1 tw-gap-4")
            }

            if (displayMode === 'fullWidth' || !displayMode && propertyList) {
                const itemsCount = propertyList.length;
                let itemsInRow = Math.min(1, itemsCount); // maximum 1

                if (itemsCount > 5) {
                    if (itemsCount % 2 === 0 || itemsCount % 3 === 0) {
                        itemsInRow = Math.min(2, Math.ceil(itemsCount / 2)); // Or you can use itemsCount / 3 if you prefer 3 rows
                    } else {
                        itemsInRow = 2;
                    }
                }

                setGridClasses(`tw-grid sm:tw-grid-cols-1 md:tw-grid-cols-${itemsInRow} lg:tw-grid-cols-${itemsInRow} tw-gap-4 tw-gap-y-6`);
            }
        }


   
    }, [displayMode, propertyList]);

    if (!propertyList) {
      
        return "No Properties"
    }

    function handlePageClick(e) {
        const sortPanelElement = document.getElementById('SortPanel');
        if (sortPanelElement) {
            sortPanelElement.scrollIntoView({ behavior: 'smooth' });
        }
        sendParams({ Show: "21", Page: e.selected + 1, clearcache: false });
    }

 
    const listItems = propertyList.map((item, index) => {
        return (
            <React.Fragment key={index}>
                {isFetching ? <SkeletonProperty count={20} /> : <PropertyItem item={item} enableSlider={true} dictionary={dictionary} />}
            </React.Fragment>
        );
    });
 
    return (
        <div className={"lg:tw-columns-1"}>
            <div className={gridClasses}>
                {listItems}
            </div>
            {total > 20 ? <ReactPaginate
                className='pagination tw-mt-5'
                breakLabel="..."
                previousLabel="<"
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={3}
                pageCount={pageCount}
                pageClassName='page-item'
                pageLinkClassName="page-link"
                previousClassName="page-item "
                nextClassName="page-item"
                previousLinkClassName="page-link"
                nextLinkClassName="page-link"

                activeClassName="active tw-cursor-default"
                disabledClassName='disabled'
                breakClassName="page-item"
                breakLinkClassName='page-link'
            /> : ""}
        </div>


    );
}


export default PropertiesList;