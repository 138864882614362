import React from 'react';
import styles from './BreadCrumbs.module.css';

const BreadCrumbs = ({ data }) => {
    return (
        <div className={`breadcrumbs tw-pb-3 tw-inline-block ${styles.customClass}`}>
            {data.map((item, index) => (
                <span key={index}>
                    {index < data.length - 1 ? (
                        <>
                            <a href={item.Url} className="tw-text-black tw-no-underline tw-text-xs tw-uppercase">{item.Title}</a>
                            {" / "}
                        </>
                    ) : (
                            <span className="tw-text-primary tw-uppercase tw-text-xs tw-font-montserrat">{item.Title}</span>
                    )}
                </span>
            ))}
        </div>
    );
};

export default BreadCrumbs;