import React from 'react';
import PropTypes from 'prop-types';
import styles from './PropertyCard.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBed } from '@fortawesome/free-solid-svg-icons';
import { faPerson } from '@fortawesome/free-solid-svg-icons';
import { faBath } from '@fortawesome/free-solid-svg-icons';
import WishButton from 'components/SearchResult/WishButton/WishButton';
 

const PropertyCard = ({ item, handleOnClose, map }) => {
 
  return (
    <div className={styles.PropertyCard + " propertyItem overflow-hidden  tw-relative shadow rounded tw-w-[250px] !tw-bg-[#fff]    "}>
      <span className="tw-bg-[#fff] tw-absolute tw-right-3 tw-top-3 tw-rounded-full tw-p-1" onClick={handleOnClose}><button type="button" className="btn-close " aria-label="Close"></button></span>
      <a href={item.MoreDetailsURL} target="_blank" rel="noreferrer">
        <img src={'https://' + item.PropertyImagesUrls[0]} alt='img' className='img-responsive' />
      </a>
      
      <WishButton id={item.Id} heartColor="red" size="2xl" className='tw-absolute tw-z-1 tw-left-3 tw-top-3 tw-cursor-pointer'  />
      <div className="tw-p-2">
        <h5 className="text-primary tw-m-0">{item.PropertyName}</h5>
        <div className="tw-m-0 tw-text-[1rem]">{item.Location}</div>
        <div className="search-result-attribute-list tw-flex">
          <div className="attribute-item tw-text-[1.5rem] tw-mr-5">
            <span className="tw-py-2">{item.Sleeps} </span>
            <FontAwesomeIcon icon={faPerson} color="#183153" className="tw-right-5 tw-top-5  " />
          </div>
          <div className="attribute-item tw-text-[1.5rem] tw-mr-5">
            <span className="tw-py-2">{item.Bedrooms} </span>
            <FontAwesomeIcon icon={faBed} color="#183153" className="tw-right-5 tw-top-5  " />
          </div>
          <div className="attribute-item tw-text-[1.5rem] tw-mr-5">
            <span className="tw-py-2">{item.Bathrooms} </span>
            <FontAwesomeIcon icon={faBath} color="#183153" className="tw-right-5 tw-top-5  " />
          </div>
        </div>
      </div>
    </div>
  )
};

PropertyCard.propTypes = {};

PropertyCard.defaultProps = {};

export default PropertyCard;
