import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import Skeleton from "react-loading-skeleton";
import styles from './SocialNetworks.module.css';

import { faFacebook } from "@fortawesome/free-brands-svg-icons"
import { faPinterest } from "@fortawesome/free-brands-svg-icons"
import { faGoogle } from "@fortawesome/free-brands-svg-icons"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { faYoutube } from "@fortawesome/free-brands-svg-icons"
import { faFlickr } from "@fortawesome/free-brands-svg-icons"
import { faInstagram } from "@fortawesome/free-brands-svg-icons"
import { faBlog } from "@fortawesome/free-solid-svg-icons"


 
const SocialNetworks = (props) =>{

  
  const [facebook, setFacebook] = useState(props.socialNetworks.Facebook);
  const [pinterest, setPinterest] = useState(props.socialNetworks.Pinterest);
  const [google, setGoogle] = useState(props.socialNetworks.GooglePlus);
  const [twitter, setTwitter] = useState(props.socialNetworks.Twitter);
  const [linkedIn, setLinkedIn] = useState(props.socialNetworks.LinkedIn);
  const [youtube, setYoutube] = useState(props.socialNetworks.Youtube);
  const [flickr, setFlickr] = useState(props.socialNetworks.Flickr);
  const [instagram, setInstagram] = useState(props.socialNetworks.Instagram);
  const [blog, setBlog] = useState();
  const [iconSize, setSize] = useState()
  const [inconsClass, setIconsClass] = useState()

  useEffect(() => {
    setFacebook(props.socialNetworks.Facebook);
    setPinterest(props.socialNetworks.Pinterest);
    setGoogle(props.socialNetworks.GooglePlus);
    setTwitter(props.socialNetworks.Twitter);
    setLinkedIn(props.socialNetworks.LinkedIn);
    setYoutube(props.socialNetworks.YouTube);
    setFlickr(props.socialNetworks.Flickr);
    setInstagram(props.socialNetworks.Instagram);
    setBlog(props.socialNetworks.Blog);
    setSize(props.size);
    setIconsClass(props.wrapperClass);
     
  }, [props]);

  //console.log(props.socialNetworks);
  //console.log(props.color);
 
   
  return (
    <div className={styles.SocialNetworks + ' tw-gap-16 tw-flex tw-justify-center SocialNetworks ' + inconsClass}>

      {instagram &&
        <a target="_blank" rel="noreferrer" href={instagram} >
          <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.8196 2.29977C24.8212 2.29977 31.3395 8.81805 31.3395 16.8196C31.3395 24.8212 24.8212 31.3395 16.8196 31.3395C8.81805 31.3395 2.29977 24.8212 2.29977 16.8196C2.29977 8.81805 8.81805 2.29977 16.8196 2.29977ZM16.8196 0C7.52528 0 0 7.52528 0 16.8196C0 26.1139 7.52528 33.6392 16.8196 33.6392C26.114 33.6392 33.6392 26.1139 33.6392 16.8196C33.6392 7.52528 26.114 0 16.8196 0Z" fill="#C5A05F" />
            <path d="M25.7602 12.4105C25.6377 11.2402 25.1887 10.206 24.3177 9.37589C23.406 8.50497 22.2765 8.11034 21.0518 8.0423C18.9562 7.93343 13.9892 7.85179 12.2338 8.13756C10.2334 8.46415 8.92699 9.62084 8.38267 11.594C7.96082 13.1045 8.04246 20.0991 8.28741 21.596C8.62761 23.6508 9.83874 24.9572 11.8663 25.4879C13.268 25.8553 20.1809 25.8009 21.7322 25.5559C23.787 25.2293 25.107 24.0182 25.6377 21.9906C26.046 20.4393 25.9099 13.8257 25.7602 12.4105ZM24.1272 21.3238C23.8823 22.8751 22.8617 23.8413 21.2968 24.0182C19.8543 24.1815 13.4449 24.2632 12.1113 23.8957C10.8321 23.5419 10.1109 22.6574 9.89316 21.3782C9.68904 20.1535 9.63461 14.0299 9.89316 12.3833C10.1245 10.8456 11.1587 9.87939 12.71 9.70249C14.2886 9.52558 19.9632 9.51197 21.4873 9.74331C23.0386 9.98826 24.0048 11.0225 24.1681 12.5874C24.3177 14.0299 24.3586 19.7453 24.1272 21.3238ZM16.9966 12.288C14.4791 12.288 12.4243 14.3292 12.4243 16.8603C12.4243 19.3778 14.4655 21.4327 16.9966 21.4327C19.5141 21.4327 21.5689 19.3915 21.5689 16.8603C21.5689 14.3292 19.5277 12.288 16.9966 12.288ZM16.9694 19.8133C15.3364 19.7997 14.03 18.4661 14.0436 16.8331C14.0572 15.2002 15.3908 13.8938 17.0238 13.9074C18.6568 13.921 19.9632 15.2546 19.9495 16.8876C19.9359 18.5069 18.6024 19.8269 16.9694 19.8133ZM22.8209 12.1111C22.8209 12.6963 22.3446 13.1862 21.7458 13.1726C21.1607 13.1726 20.6708 12.6963 20.6844 12.0975C20.6844 11.5124 21.1607 11.0225 21.7594 11.0361C22.3446 11.0361 22.8209 11.526 22.8209 12.1111Z" fill="#C5A05F" />
          </svg>

        </a>
      }

      {twitter &&
        <a target="_blank" rel="noreferrer" href={twitter} >
          <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.8196 2.29977C24.8212 2.29977 31.3395 8.81805 31.3395 16.8196C31.3395 24.8212 24.8212 31.3395 16.8196 31.3395C8.81805 31.3395 2.29977 24.8212 2.29977 16.8196C2.29977 8.81805 8.81805 2.29977 16.8196 2.29977ZM16.8196 0C7.52528 0 0 7.52528 0 16.8196C0 26.1139 7.52528 33.6392 16.8196 33.6392C26.1139 33.6392 33.6392 26.1139 33.6392 16.8196C33.6392 7.52528 26.1139 0 16.8196 0Z" fill="#C5A05F" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7577 21.8C11.7028 21.732 10.4237 20.2487 10.0835 19.0512C10.655 19.1601 11.213 19.1328 11.7845 18.9968C11.7981 18.9968 11.8117 18.9832 11.8389 18.9695C10.7231 18.711 9.86575 18.1122 9.26699 17.1597C8.87236 16.5201 8.68185 15.8261 8.68185 15.0504C9.23978 15.3498 9.82493 15.5131 10.4373 15.5267C9.59359 14.9279 9.03566 14.1387 8.80432 13.1317C8.57298 12.1247 8.72267 11.1721 9.22617 10.2604C11.3626 12.7779 14.0843 14.1659 17.3774 14.3972C17.3502 14.2475 17.3366 14.0978 17.3094 13.9618C17.2005 13.0364 17.391 12.1655 17.8809 11.3762C18.5069 10.3828 19.405 9.77047 20.5617 9.59356C21.8953 9.37583 23.052 9.75686 24.0182 10.6958C24.0862 10.7639 24.1407 10.7775 24.2223 10.7639C25.0388 10.587 25.8008 10.3012 26.5221 9.87933C26.5357 9.86573 26.5629 9.86573 26.5765 9.85212C26.5765 9.85212 26.5901 9.85212 26.6037 9.85212C26.2907 10.7775 25.7192 11.4851 24.9163 12.0022C25.6784 11.9205 26.4132 11.7164 27.1208 11.4034L27.1344 11.4171C26.9847 11.6212 26.8351 11.8117 26.6854 12.0158C26.2635 12.5329 25.7872 12.982 25.2429 13.3766C25.2021 13.4038 25.1749 13.4447 25.1749 13.4991C25.2021 14.0842 25.1749 14.6694 25.1068 15.2545C24.9571 16.4793 24.6169 17.6496 24.0862 18.779C23.5419 19.9357 22.8343 20.9835 21.9497 21.8953C20.4392 23.4466 18.6294 24.4536 16.5201 24.9299C15.7989 25.0932 15.064 25.1748 14.3156 25.1884C12.0294 25.2565 9.93379 24.6849 7.98783 23.4874C7.96062 23.4738 7.9334 23.4602 7.89258 23.433C9.267 23.5827 10.587 23.4058 11.8525 22.8887C12.5465 22.6301 13.1589 22.2627 13.7577 21.8Z" fill="#C5A05F" />
          </svg>

        </a>
      }


      {facebook ?
        <a  target="_blank"  rel="noreferrer" href={facebook} >
          <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.8196 2.29977C24.8212 2.29977 31.3395 8.81805 31.3395 16.8196C31.3395 24.8212 24.8212 31.3395 16.8196 31.3395C8.81805 31.3395 2.29977 24.8212 2.29977 16.8196C2.29977 8.81805 8.81805 2.29977 16.8196 2.29977ZM16.8196 0C7.52528 0 0 7.52528 0 16.8196C0 26.1139 7.52528 33.6392 16.8196 33.6392C26.1139 33.6392 33.6392 26.1139 33.6392 16.8196C33.6392 7.52528 26.1139 0 16.8196 0Z" fill="#C5A05F" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.9832 10.5735C19.5956 10.5599 20.2079 10.5735 20.8203 10.5735C20.8883 10.5735 20.97 10.5735 21.0789 10.5735V7.38921C20.7523 7.362 20.4121 7.30756 20.0719 7.29396C19.4459 7.26674 18.8199 7.23952 18.1939 7.25313C17.2414 7.26674 16.3296 7.51169 15.5404 8.06962C14.6286 8.72281 14.1387 9.64816 13.9482 10.7232C13.8666 11.1723 13.853 11.6349 13.8393 12.084C13.8257 12.7916 13.8393 13.5129 13.8393 14.2205V14.4926H10.7639V18.0444H13.7849V26.9849H17.4863V18.058H20.5073C20.657 16.8741 20.8067 15.7038 20.97 14.4926C20.725 14.4926 20.5073 14.4926 20.2896 14.4926C19.4323 14.4926 17.4591 14.4926 17.4591 14.4926C17.4591 14.4926 17.4727 12.7372 17.4863 11.9615C17.5271 10.9273 18.1395 10.6007 18.9832 10.5735Z" fill="#C5A05F" />
          </svg>


        </a> : <Skeleton height={23} width={23} />
      }

      {pinterest &&
        <a  target="_blank"  rel="noreferrer" href={pinterest} >
          <FontAwesomeIcon size={iconSize} icon={faPinterest} color={props.color} className={props.className} />
        </a>
      }

      {google &&
        <a  target="_blank"  rel="noreferrer" href={google} >
          <FontAwesomeIcon size={iconSize} icon={faGoogle} color={props.color} className={props.className} />
        </a>
      }


      {linkedIn &&
        <a  target="_blank"  rel="noreferrer" href={linkedIn} >
          <FontAwesomeIcon size={iconSize} icon={faLinkedin} color={props.color} className={props.className} />
        </a>
      }

      {youtube &&
        <a  target="_blank"  rel="noreferrer" href={youtube} >
          <svg width="33" height="34" viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_584_448)">
              <path d="M16.5 2.32443C24.3495 2.32443 30.7439 8.91262 30.7439 17C30.7439 25.0874 24.3495 31.6756 16.5 31.6756C8.65049 31.6756 2.25607 25.0874 2.25607 17C2.25607 8.91262 8.65049 2.32443 16.5 2.32443ZM16.5 0C7.38228 0 0 7.60599 0 17C0 26.394 7.38228 34 16.5 34C25.6177 34 33 26.394 33 17C33 7.60599 25.6177 0 16.5 0Z" fill="#C5A05F" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.61168 22.9006C8.95756 22.6805 8.51702 22.2404 8.27673 21.5665C7.7027 19.9297 7.52916 13.094 8.63717 11.7461C9.01095 11.2922 9.50489 11.0171 10.0923 10.9483C13.0826 10.6182 22.3338 10.6595 23.4017 11.0584C24.0292 11.2922 24.4697 11.7048 24.71 12.3513C25.3374 14.043 25.3641 20.1911 24.6299 21.814C24.4296 22.2542 24.1093 22.5705 23.6954 22.7906C22.5741 23.4095 11.0801 23.3957 9.61168 22.9006ZM14.3641 19.6959C16.1663 18.7331 17.9418 17.7841 19.7573 16.8213C17.9418 15.8448 16.1663 14.8957 14.3641 13.9192C14.3641 15.8448 14.3641 17.7566 14.3641 19.6959Z" fill="#C5A05F" />
            </g>
            <defs>
              <clipPath id="clip0_584_448">
                <rect width="33" height="34" fill="white" />
              </clipPath>
            </defs>
          </svg>

        </a>
      }


      {flickr &&
        <a  target="_blank"  rel="noreferrer" href={flickr} >
          <FontAwesomeIcon size={iconSize} icon={faFlickr} color={props.color} className={props.className} />
        </a>
      }


      
      {blog &&
        <a  target="_blank"  rel="noreferrer" href={blog} >
          <FontAwesomeIcon size={iconSize} icon={faBlog} color={props.color} className={props.className} />
        </a>
      }

    
    </div>
  )
};
 

export default SocialNetworks;
