import React, { useEffect, useState, useContext } from 'react';
import styles from './TopBar.module.css';
import Navigation from 'layouts/Navigation/Navigation';
import Header from 'layouts/Header/Header';
import Breadcrumb from 'layouts/Breadcrumb/Breadcrumb';
import { SiteSettingsContext } from '../../contexts/SiteSettingsProvider';


const TopBar = ( ) => {
 
  let settings = useContext(SiteSettingsContext);
  let [breadcrumbSettings, setBreadcrumbSettings] = useState('');
  let [headerSettings, setHeaderSettings] = useState('');
  let [navigationSettings, setNavigationSettings] = useState('');
  let [siteSettings, setSiteSettings] = useState('');
  let [bodyClasses, setbodyClasses] = useState('');

  let [keyID, setKey] = useState('');

  let [currency, setCurrency] = useState('');
  let [currencies, setCurrencies] = useState('');
  let [socialNetworks, setSocialNetworks] = useState('');
  let [languages, setLanguages] = useState('');
  let [language, setLanguage] = useState('');
  let [topRightLinks, setTopRightLinks] = useState('');

  useEffect(() => {
    if (sessionStorage.getItem("sitesettings") !== null) {
      let response = window.sessionStorage.getItem("sitesettings");
      response = JSON.parse(response);
      setSiteSettings(response.SiteSettings);
      setBreadcrumbSettings(response.BreadcrumbsSettings);
      setHeaderSettings(response.HeaderSettings);
      setNavigationSettings(response.NavigationSettings);
      setCurrency(response.Currency);
      setCurrencies(response.Currencies);
      setSocialNetworks(response.SocialNetworks);
      setLanguages(response.Languages);
      setLanguage(response.Language);
      setTopRightLinks(response?.NavigationSettings?.TopRightMenuNodes);
      setbodyClasses(response?.SiteSettings?.BodyClasses);
    }

    if (settings){
      window.sessionStorage.setItem("sitesettings", JSON.stringify(settings));
      setSiteSettings(settings.SiteSettings);
      setBreadcrumbSettings(settings.BreadcrumbsSettings);
      setHeaderSettings(settings.HeaderSettings);
      setNavigationSettings(settings.NavigationSettings);
      setCurrency(settings.Currency);
      setCurrencies(settings.Currencies);
      setSocialNetworks(settings.SocialNetworks);
      setLanguages(settings.Languages);
      setLanguage(settings.Language);
      setTopRightLinks(settings.NavigationSettings.TopRightMenuNodes);
      setbodyClasses(settings.SiteSettings.BodyClasses);
    }
  }, [settings]);

  return (
    <div className={styles.TopBar +' TopBar'}>
        <Breadcrumb breadcrumbSettings={breadcrumbSettings} topRightLinks={topRightLinks} socialNetworks={socialNetworks} siteSettings={siteSettings} bodyClasses={bodyClasses} />
        <Header headerSettings={headerSettings} siteSettings={siteSettings} socialNetworks={socialNetworks} bodyClasses={bodyClasses} />
        <Navigation navigationSettings={navigationSettings} currency={currency} currencies={currencies} languages={languages} language={language} keyID={keyID} siteSettings={siteSettings} bodyClasses={bodyClasses} />
    </div>
  );
};

 
export default TopBar;
