import React, { useState, useEffect, useRef } from 'react';
import styles from './SearchControlPanel.module.css';
import MapSwitcher from 'components/SearchResult/SearchControlPanel/MapSwitcher/MapSwitcher';
import SearchSorter from 'components/SearchResult/SearchControlPanel/SearchSorter/SearchSorter';
import useWindowSize from "utils/useWindowSize";

const SearchControlPanel = ({ upMapState, updateSorting }) => {
  const { width } = useWindowSize();
  const [showSwitcher, setShowSwitcher] = useState(false);

  useEffect(() => {
 
    if (width < window.lg) {
      setShowSwitcher(true)
    }else{
      setShowSwitcher(false)
    }
  }, [width]);

  return (
    <div id="SortPanel" className={styles.SearchControlPanel + " tw-flex tw-justify-end tw-flex lg:tw-justify-between tw-bg-slate-100 tw-p-2 tw-content-center tw-mb-4"}>

      {showSwitcher && <MapSwitcher upMapState={upMapState} shown={true} text="Hide Map" />}
      <SearchSorter updateSorting={updateSorting} />
    </div>
  )
};

SearchControlPanel.propTypes = {};

SearchControlPanel.defaultProps = {};

export default SearchControlPanel;