import React, { useState, useEffect } from 'react';
import './FlexibleNights.css';

const FlexibleNights = ({ changeFlexability }) => {
    const [selectedOption, setSelectedOption] = useState('exact');

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const flexiblenightsParam = urlSearchParams.get('flexiblenights');

        if (flexiblenightsParam === '3') {
            setSelectedOption('flexible3');
        } else if (flexiblenightsParam === '7') {
            setSelectedOption('flexible7');
        } else {
            setSelectedOption('exact');
        }
    }, []);

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
        changeFlexability(e.target.value);
    };

    return (
        <div>
            <h6 className="tw-text-md tw-font-medium tw-mt-3 tw-uppercase">DATE</h6>
            <form>
                <div className="custom-radio">
                    <input
                        type="radio"
                        id="exact"
                        value="exact"
                        checked={selectedOption === 'exact'}
                        onChange={handleOptionChange}
                    />
                    <label htmlFor="exact">Exact Search</label>
                </div>
                <div className="custom-radio">
                    <input
                        type="radio"
                        id="flexible3"
                        value="3"
                        checked={selectedOption === '3'}
                        onChange={handleOptionChange}
                    />
                    <label htmlFor="flexible3">I am flexible +/- 3 days</label>
                </div>
                <div className="custom-radio">
                    <input
                        type="radio"
                        id="flexible7"
                        value="7"
                        checked={selectedOption === '7'}
                        onChange={handleOptionChange}
                    />
                    <label htmlFor="flexible7">I am flexible +/- 7 days</label>
                </div>
            </form>
           
        </div>
    );
};

export default FlexibleNights;