import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import useGetUrlParams from 'utils/useGetUrlParams';
import Multiselect from 'multiselect-react-dropdown';

const LocationMobile = ({ onToggle, changeLocation, locationList, enableLocationFilter, clearField, onCloseSelect, settings, pageID, isOpen }) => {
 
    const [selectedOption, setSelectedOption] = useState([]);

    let [locationPlaceholder, setLocationPlaceholder] = useState("Anywhere");
    let [Location] = useGetUrlParams('Location');

 
    useEffect(() => {
        if (clearField > 0) {
            setSelectedOption([]);
            changeLocation('');
            setLocationPlaceholder("Anywhere");
        }
    }, [clearField]);

    useEffect(() => {
        if (pageID && locationList) {
            const optionMatchingPageID = options.find(option => option.id === pageID);

            if (optionMatchingPageID) {

                setSelectedOption([{ name: optionMatchingPageID.name, id: optionMatchingPageID.id }]);
                setTimeout(() => {
                    changeLocation(pageID.toString());
                }, 1);
            }
        }
    }, [Location, pageID, locationList]);


    useEffect(() => {
        let urlParams = new URLSearchParams(window.location.search);
        let location = urlParams.get('location') || urlParams.get('Location');


        if (!Location && location) {
            Location = location;
        }


        if (Location === '') {
            changeLocation('');
        }


        if (Location) {
            for (let i = 0; i < Location.length; ++i) {
                changeLocation(location);
            }

            const selectedIds = Location.split(',').filter(Boolean).map(Number);
            const selectedOptions = options.filter(option => selectedIds.includes(option.id))
                .map(option => ({ id: option.id, name: option.name }));
            setSelectedOption(selectedOptions);

            const isEmpty = selectedOptions.length === 0;
            const length = selectedOptions.length;
            if (!isEmpty) {
                setLocationPlaceholder(`Locations (${length})`);
            } else {
                setLocationPlaceholder(`Anywhere`);
            }
        }

        if (Location === 'All') {
            setLocationPlaceholder("Anywhere");
        }

    }, [Location]);


    let options = locationList?.map(({ Id, Name }) => ({
        name: Name,
        id: Id,
        selected: selectedOption.some(option => option.id === Id)
    })) || [];


    function changeLocaationParameter(e) {

        if (e) {
            if (e.length === 0) setLocationPlaceholder(`Anywhere`);
            if (e.length > 0) {
                setLocationPlaceholder(`Locations (${e.length})`);
            }
            const ids = e.map(obj => obj.id).join(',');
            changeLocation(ids);
        }
    }

    function handleCheckboxChange(e, option) {
        const updatedSelectedOptions = e.target.checked
            ? [...selectedOption, option]
            : selectedOption.filter(selected => selected.id !== option.id);

        setSelectedOption(updatedSelectedOptions);
        changeLocaationParameter(updatedSelectedOptions);
    }

 

    return (
        <>
            <div className={isOpen ? "tw-border tw-p-5" : ""}>
                <div className={!isOpen ? "tw-border tw-p-5" : ""} onClick={onToggle}>
                    <h5 className='tw-m-0'>Where to?</h5>
                </div>
                {isOpen && <div>
                    {enableLocationFilter && options.length > 0 && (
                        <div className="location-buttons tw-mt-5">
                            {options.map(option => (
                                <label key={option.id} className={`location-button ${option.selected ? 'selected' : ''}`}>
                                    <input
                                        type="checkbox"
                                        checked={option.selected}
                                        onChange={e => handleCheckboxChange(e, option)}
                                        className="hidden-checkbox"
                                    />
                                    {option.name}
                                </label>
                            ))}
                        </div>
                    )}
                </div>}
            </div>
        </>
    );
};


export default LocationMobile;
