 
import "./Slider.css";

function Slider({ url,title, subtitle, children}) {
 
 
    return (
        <div 
            style={{ backgroundImage: `url(https://www.goldentriangleproperties.com/${url})` }}
            className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-full tw-min-h-[50vh] tw-bg-no-repeat tw-bg-cover tw-bg-center"
        >
            <div className="tw-max-w-[85%] tw-text-center">
                <h1 className="tw-text-primary">{title}</h1>
                <h4 className="tw-text-primary tw-font-light tw-uppercase tw-font-extralight ">{subtitle}</h4>
            </div>
            {children}
        </div>
    );   
}

export default Slider;
 


