import React, { useEffect, useState, useContext } from 'react';
import { Link, Element, Events } from 'react-scroll';
import { SiteSettingsContext, CurrencyContext } from '../../contexts/SiteSettingsProvider';
import useWindowSize from "utils/useWindowSize";
import "tailwindcss/tailwind.css";

import CalendarDataContext from '../../contexts/CalendarDataContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faList, faCalendar, faMapMarkerAlt, faStar, faDollarSign } from '@fortawesome/free-solid-svg-icons';
// New imports
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './property.scss';

// New imports
import ImageSlider from '../../components/PropertyPage/ImageSlider/ImageSlider';

import BookingForm from '../../components/PropertyPage/BookingForm/BookingForm';
import BookingFormMobile from '../../components/PropertyPage/BookingFormMobile/BookingFormMobile';

import Description from '../../components/PropertyPage/Description/Description';
import Amenities from '../../components/PropertyPage/Amenities/Amenities';
import Rates from '../../components/PropertyPage/Rates/Rates';
import AvailabilityCalendar from '../../components/PropertyPage/AvailabilityCalendar/AvailabilityCalendar';
import BreadCrumbs from '../../components/PropertyPage/BreadCrumbs/BreadCrumbs';
 
import Map from '../../components/PropertyPage/Map/Map';
import Reviews from '../../components/PropertyPage/Reviews/Reviews';
import MainAttrs from '../../components/PropertyPage/MainAttrs/MainAttrs';
import { set } from 'date-fns';

const icons = {
    Description: faHome,
    Amenities: faList,
    Rates: faDollarSign,
    Availability: faCalendar,
    Map: faMapMarkerAlt,
    Reviews: faStar,
};

const MenuItem = ({ name, to }) => {
    return (
        <Link
            activeclassName="!tw-bg-white"
            to={to}
            spy={true}
            smooth={true}
            duration={300}
            className="tw-cursor-pointer tw-no-underline tw-flex tw-items-center tw-block tw-w-full tw-py-2 tw-bg-gray-100 menuitem tw-text-center"
        >
            <FontAwesomeIcon icon={icons[name]} className="tw-mr-2 md:tw-hidden" />
            <span className="lg:tw-inline tw-hidden">{name}</span>
        </Link>
    );
};

const Property = ({ pageId, keyID, GoogleMapAPIKey }) => {
    const [isLoading, setIsLoading] = useState(true);
    const windowSize = useWindowSize();
    const [currency, setCurrency] = useContext(CurrencyContext);
    const [calendarData, setCalendarData] = useState([]);
    const [isMobile, setIsMobile] = useState(false);

    const [selectedDates, setSelectedDates] = useState({ startDate: null, endDate: null });
    const [images, setImages] = useState([]);
    const [reviews, setReviews] = useState([]);

    const [pageH1Title, setPageH1Title] = useState('');
    const [intro, setIntro] = useState('');
    const [description, setDescription] = useState('');
    const [propertyRates, setPropertyRates] = useState([]);
    const [geoLocation, setGeoLocation] = useState([]);
    const [distances, setDistances] = useState([]);
    const [locationDescription, setLocationDescription] = useState('');
    const [propertyAttributes, setPropertyAttributes] = useState({});
    const [floorPlan, setFloorPlan] = useState('');
    

    const [cons, setCons] = useState('');
    const [pros, setPros] = useState('');

    const [maxOccupancy, setMaxOccupancy] = useState(1);
    const [maxPets, setMaxPets] = useState(1);
    const [currencySymbol, setCurrencySymbol] = useState("£");
    const [hideCalendar, setHideCalendar] = useState(false);
    const [disableAvailability, setDisableAvailability] = useState(false);
    const [hideRates, setHideRates] = useState(false);
    const [plotSize, setPlotSize] = useState(null);
    const [propertyLicence, setPropertyLicence] = useState('');
    const [propertyReference, setPropertyReference] = useState('');
    const [regionDescription, setRegionDescription] = useState('');
    const [propertyRentalNotes, setPropertyRentalNotes] = useState('');
    const [virtualTour, setVirtualTour] = useState('');
    const [virtualTourTitle, setVirtualTourTitle] = useState('');
    const [breadcrumbs, setBreadcrumbs] = useState(null);
    const [propertyMaxRate, setPropertyMaxRate] = useState(0);
    const [propertyMinRate, setPropertyMinRate] = useState(0);
    const [propertyName, setPropertyName] = useState('');
 
    useEffect(() => {
        if (windowSize.width > 800) {
            setIsMobile(false);
        }

        if (windowSize.width < 800) {
            setIsMobile(true);
        }
    }, [windowSize.width]);

 
    
    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_URL}apis/propertypage?pageid=${pageId}&Currency=${currency}`) //${currency}`)
            .then(response => response.json())
            .then(data => {
                
                setIntro(data.Property.Details.Intro);
                setCons(data.Property.Details.Cons);
                setPros(data.Property.Details.Pros);
                setPageH1Title(data.Property.Details.PageH1Title);
                setReviews(data.Property.Details.Reviews);
                setDescription(data.Property.Details.MainDescription);
                setImages(data.Property.Details.SliderImages);
                setCalendarData(data.Property.Details.Calendar);
                setFloorPlan(data.Property.Details.FloorPlan);
 
                setPropertyRates(data.Property.Details.PropertyRates);
                setPropertyAttributes(data.Property.Details.PropertyAttributes);
                setGeoLocation(data.Property.Details.GeoLocation);
                setDistances(data.Property.Details.Distances);
                setLocationDescription(data.Property.Details.LocationDescription);
                 
                setIsLoading(false);  // Data has been fetched successfully

                setMaxOccupancy(data.Property.Details.MaxOccupancy);
                setMaxPets(data.Property.Details.MaxPets);
                setCurrencySymbol(data.Property.Details.CurrencySymbol);
                setHideCalendar(data.Property.Details.HideCalendar);
                setDisableAvailability(data.Property.Details.DisableAvailability);
                setHideRates(data.Property.Details.HideRates);
                setPlotSize(data.Property.Details.PlotSize);
                setPropertyLicence(data.Property.Details.PropertyLicence);
                setPropertyReference(data.Property.Details.PropertyReference);
                setRegionDescription(data.Property.Details.RegionDescription);
                setPropertyRentalNotes(data.Property.Details.PropertyRentalNotes);
                setVirtualTour(data.Property.Details.VirtualTour);
                setVirtualTourTitle(data.Property.Details.VirtualTourTitle);
                setBreadcrumbs(data.Settings.BreadCrumbs);
                setPropertyMaxRate(data.Property.Details.PropertyMaxRate);
                setPropertyMinRate(data.Property.Details.PropertyMinRate);
                setCurrency(data.Property.Details.CurrencySymbol);
                setPropertyName(data.Property.Details.PropertyName);
            })
            .catch(error => {
                console.error('Error fetching blocked dates:', error);
                setIsLoading(false);  // Data has been fetched successfully
            });
    }, [currency]);


 

    useEffect(() => {
        Events.scrollEvent.register('begin', (to, element) => {
            console.log('begin', to, element);
        });

        Events.scrollEvent.register('end', (to, element) => {
            console.log('end', to, element);
        });

        return () => {
            Events.scrollEvent.remove('begin');
            Events.scrollEvent.remove('end');
        };
    }, []);


    const handleDateChange = (startDate, endDate) => {
        setSelectedDates({ startDate, endDate });
    };

    return (
        <div className=' tw-relative tw-z-[1]'>
            
            <div className="tw-w-full tw-overflow-hidden tw-relative tw-z-[1]">
                {/* Image slider */}
                <ImageSlider images={images} isLoading={isLoading} />
                <MainAttrs propertyAttributes={propertyAttributes} id={pageId} propertyReference={propertyReference} />    
                <div className="md:tw-bg-transparent md:tw-absolute tw-text-primary md:tw-text-white tw-inset-0 md:tw-m-auto lg:tw-w-[600px] tw-h-[auto] md:tw-flex md:tw-justify-center md:tw-items-center tw-z-10">
                    <div className="md:tw-text-center tw-font-extralight tw-p-3 md:tw-p-0">
 
                        <h1 class="md:tw-text-3xl tw-mb-0 tw-font-light property-page-title ">
                            
                            
                            {pageH1Title}
                        </h1>
                    </div>     
                </div>
            </div>

            <div className="tw-container tw-mx-auto tw-py-2 tw-z-[9999999] tw-relative tw-p-3 sm:tw-p-5 md:tw-p-0">

                <div className={`tw-z-90 tw-relative tw-w-full tw-grid tw-grid-cols-12 tw-gap-4 tw-mt-2 ${windowSize.width < 1024 ? 'tw-grid-flow-row-dense' : ''}`}>
                    {/* Sidebar */}
                    <div className={`tw-col-span-12 ${windowSize.width >= 1024 ? 'lg:tw-col-span-4 tw-order-2' : 'tw-order-first'}`}>
                        <div className="tw-sticky tw-top-[0px] tw-z-[9999999] tw-white tw-shadow-2xl ">

         

                            {!isMobile &&
                                <CalendarDataContext.Provider value={calendarData} isLoading={isLoading}>
                                    {<BookingForm
                                        selectedDates={selectedDates}
                                        onDateChange={handleDateChange}
                                        maxOccupancy={maxOccupancy}
                                        maxPets={maxPets}
                                        currencySymbol={currency}
                                        pageId={pageId}
                                        disableAvailability={disableAvailability}
                                    />}
                                </CalendarDataContext.Provider>
                            }



                            {isMobile &&
                                <CalendarDataContext.Provider value={calendarData} isLoading={isLoading}>
                                    {<BookingFormMobile
                                        selectedDates={selectedDates}
                                        onDateChange={handleDateChange}
                                        maxOccupancy={maxOccupancy}
                                        maxPets={maxPets}
                                        currencySymbol={currencySymbol}
                                        pageId={pageId}
                                        disableAvailability={disableAvailability}
                                    />}
                                </CalendarDataContext.Provider>
                            }  
 
                        </div>
                    </div>
                    {/* Main content */}
                    <div className={`tw-col-span-12 ${windowSize.width >= 1024 ? 'lg:tw-col-span-8 tw-order-1' : 'tw-order-last'}`}>


                        <div className="  ">
                           

                            {(breadcrumbs && breadcrumbs.length > 0) && <BreadCrumbs data={breadcrumbs} />}


                            <div className="tw-sticky tw-top-0 tw-z-50 tw-bg-white tw-w-full tw-flex tw-justify-around">
                                <MenuItem name="Description" to="description" />
                                <MenuItem name="Amenities" to="amenities" />
                                {!hideCalendar && <MenuItem name="Availability" to="availability" />}
                                {!hideRates && <MenuItem name="Rates" to="rates" />}
                                <MenuItem name="Map" to="map" />
                                {/* <MenuItem name="Reviews" to="reviews" /> */}
                            </div>

                            {/* Sections */}
                           
                            <Element name="description" className="property-section tw-mt-0" >
  

                             
  
                                <Description intro={intro} description={description} cons={cons} pros={pros} isLoading={isLoading} />
                            
                                {virtualTour && 
                                   <>
                                        { virtualTourTitle }
                                        <div className="virtualTour" dangerouslySetInnerHTML={{ __html: virtualTour }} />
                                   </>
                                }

                                {floorPlan && <div className="floorPlan" dangerouslySetInnerHTML={{ __html: floorPlan }} />}
                                {plotSize && <div className="plotSize" dangerouslySetInnerHTML={{ __html: plotSize }} />} 
                                {propertyLicence && 
                                <>
                                    <b>Property Licence: </b>
                                    <span className="propertyLicence tw-ml-2" dangerouslySetInnerHTML={{ __html: propertyLicence }} />
                                </>} 
                               
                            </Element>

                            <Element name="amenities" className="property-section">
                                <Amenities propertyAttributes={propertyAttributes} isLoading={isLoading} />
                            </Element>

                            {!hideCalendar &&
                                <Element name="availability" className="property-section">
                                    <CalendarDataContext.Provider value={calendarData}>
                                        <AvailabilityCalendar selectedDates={selectedDates} onDateChange={handleDateChange} isLoading={isLoading} disableAvailability={disableAvailability} />
                                    </CalendarDataContext.Provider>

                                  
                                </Element>
                            }

                            {!hideRates &&
                                <Element name="rates" className="property-section">
                                    <Rates rates={propertyRates} isLoading={isLoading} />
                                    {propertyRentalNotes && <div className="propertyRentalNotes" dangerouslySetInnerHTML={{ __html: propertyRentalNotes }} />}  
                                 </Element>
                            }

                            <Element name="map" className="property-section">
                                <h4>Location Map</h4>
                                <Map geoLocation={geoLocation} GoogleMapAPIKey={GoogleMapAPIKey} isLoading={isLoading} distances={distances} locationDescription={regionDescription} />
                            </Element>
                            {/* <Element name="reviews" className="property-section">
                                <Reviews reviews={reviews} isLoading={isLoading} />
                            </Element> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Property;