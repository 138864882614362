import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import styles from './DatePicker.module.css';
import "react-dates/initialize";
import { DateRangePicker } from 'react-dates';
import "./_datepicker.scss";
import moment from 'moment';
import useGetUrlParams from 'utils/useGetUrlParams';
import useWindowSize from "utils/useWindowSize";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusMinus } from '@fortawesome/pro-regular-svg-icons';

const DatePicker = ({ onChangeFlexibility, enableFlexibleNightsFilter, changeDates, enableDateFilter, clearField }) => {
  const location = useLocation();
  const { width } = useWindowSize();
  const [selectedValue, setSelectedValue] = useState('option1');
  const [numberOfMonthsInCalendar, setOfMonthsInCalendar] = useState(2);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [tempStartDate, setTempStartDate] = useState(null);
  const [tempEndDate, setTempEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  let [checkIn] = useGetUrlParams('CheckIn');
  let [checkOut] = useGetUrlParams('CheckOut');


  const handleDatesChange = ({ startDate, endDate }) => {
    // update temporary states
    setTempStartDate(startDate);
    setTempEndDate(endDate);
    // update the actual start and end dates
    setStartDate(startDate);
    setEndDate(endDate);

    // Close the date picker if both dates are selected
    if (startDate && endDate) {
      setShowDatePicker(false);
    }

    // Notify the parent component if needed
    changeDates([startDate, endDate]);
  };

  const openStartDatePicker = () => {
    setFocusedInput('startDate');
    setShowDatePicker(true);
  };




  useEffect(() => {

    if (clearField > 0) {
      setStartDate(null);
      setEndDate(null);
      //changeDates();
    }
  }, [clearField]);

  useEffect(() => {
    if (checkIn && checkOut) {
      let startDate = moment(checkIn, ["DD/MM/YYYY", "YYYY-MM-DD"]).isValid() ? moment(checkIn, ["DD/MM/YYYY", "YYYY-MM-DD"]) : null;
      let endDate = moment(checkOut, ["DD/MM/YYYY", "YYYY-MM-DD"]).isValid() ? moment(checkOut, ["DD/MM/YYYY", "YYYY-MM-DD"]) : null;

      setStartDate(startDate);
      setEndDate(endDate);
      changeDates([startDate, endDate]);
    }
   
    if (checkIn === '' && checkOut === '') {
      changeDates([null, null]);
    }
  }, [checkIn, checkOut]);


  useEffect(() => {
    if (width < window.lg) {
      setOfMonthsInCalendar(1);
    }
  }, [width]);

  const handleChangeFlexibility = (event) => {
    setSelectedValue(event.target.value);
    const val = event.target.value;
    if (val === 'option1') {
      onChangeFlexibility('Exact');

    }
    if (val === 'option2') {
      onChangeFlexibility('1');
    }
    if (val === 'option3') {
      onChangeFlexibility('2');
    }
    if (val === 'option4') {
      onChangeFlexibility('3');
    }
    if (val === 'option5') {
      onChangeFlexibility('7');
    }
  };

  const getDuration = () => {
    if (startDate && endDate) {
      const duration = endDate.diff(startDate, 'days');
      const formattedStartDate = startDate.format('MMM D');
      const formattedEndDate = endDate.format('MMM D');
      return `${formattedStartDate} - ${formattedEndDate} (${duration} nights)`;
    }
    return '';
  };

  const checkIfSeachPage = () => {
    if (location.pathname === '/search' || location.pathname === '/search/') {
      return true;
    }
    return false;
  };

  const renderCalendarInfo = () => (
 
    <div className="tw-w-full tw-bg-white tw-bottom-0 tw-z-[999] tw-shadow tw-left-0 tw-border-t">
      {!checkIfSeachPage() && 
        <>
          <div className={styles.buttonGroup}>
            <label className={`${styles.button} ${selectedValue === 'option1' ? styles.selected : ''}`}>
              <input
                type="radio"
                value="option1"
                checked={selectedValue === 'option1'}
                onChange={handleChangeFlexibility} // Updated function name here
                className={styles.hiddenRadio}
              />
              Exact dates
            </label>
            <label className={`${styles.button} ${selectedValue === 'option2' ? styles.selected : ''}`}>
              <input
                type="radio"
                value="option2"
                checked={selectedValue === 'option2'}
                onChange={handleChangeFlexibility} // And here
                className={styles.hiddenRadio}
              />
              <FontAwesomeIcon
                icon={faPlusMinus}
                style={{ color: selectedValue === 'option2' ? '#000' : '#ccc' }} // Conditional color
                size='xs'
                className='tw-mr-1'
              />
              1 day
            </label>
            <label className={`${styles.button} ${selectedValue === 'option3' ? styles.selected : ''}`}>
              <input
                type="radio"
                value="option3"
                checked={selectedValue === 'option3'}
                onChange={handleChangeFlexibility} // And here
                className={styles.hiddenRadio}
              />
              <FontAwesomeIcon
                icon={faPlusMinus}
                style={{ color: selectedValue === 'option3' ? '#000' : '#ccc' }} // Conditional color
                size='xs'
                className='tw-mr-1'
              />
              2 days
            </label>
            <label className={`${styles.button} ${selectedValue === 'option4' ? styles.selected : ''}`}>
              <input
                type="radio"
                value="option4"
                checked={selectedValue === 'option4'}
                onChange={handleChangeFlexibility}
                className={styles.hiddenRadio}
              />
              <FontAwesomeIcon
                icon={faPlusMinus}
                style={{ color: selectedValue === 'option4' ? '#000' : '#ccc' }} // Conditional color
                size='xs'
                className='tw-mr-1'
              />
              3 days
            </label>
            <label className={`${styles.button} ${selectedValue === 'option5' ? styles.selected : ''}`}>
              <input
                type="radio"
                value="option5"
                checked={selectedValue === 'option5'}
                onChange={handleChangeFlexibility}
                className={styles.hiddenRadio}
              />
              <FontAwesomeIcon
                icon={faPlusMinus}
                style={{ color: selectedValue === 'option5' ? '#000' : '#ccc' }} // Conditional color
                size='xs'
                className='tw-mr-1'
              />
              7 days
            </label>
          </div>
        </>
      }
 
      <div className="">
        <div className='tw-flex tw-justify-between tw-items-center tw-p-3'>
          <div className="">
            {getDuration().length > 0 && <b>{getDuration()}</b>}

            <div
              onClick={(e) => {
                handleClear();
              }}
              className="tw-text-blue-500 tw-hover:text-blue-800 tw-cursor-pointer tw-font-bold">
              Clear Dates
            </div>
          </div>


        </div>
      </div>
    </div>
  );

  const handleClear = () => {
    setStartDate(null);
    setEndDate(null);

    setFocusedInput('startDate');
  };


  return (
    <>
      {enableDateFilter && (
        <div className={styles.DatePicker}>
          <button onClick={openStartDatePicker} className={'tw-border-r md:tw-border-r-0 tw-w-full md:tw-w-[290px] tw-border tw-border-r-0 tw-border-primary tw-h-[60px]  tw-text-left tw-p-2 tw-text-sm dates-button tw-pt-5'}>
            <span className={'tw-absolute tw-top-[5px] tw-left-[6px] tw-text-xs tw-text-primary'}>WHEN
            </span>
            {
              moment(startDate).isValid() && moment(endDate).isValid() ? `${moment(startDate).format('DD MMM YYYY')} - ${moment(endDate).format('DD MMM YYYY')}` :
                moment(startDate).isValid() ? `From ${moment(startDate).format('DD MMM YYYY')}` :
                  moment(endDate).isValid() ? `Until ${moment(endDate).format('DD MMM YYYY')}` : 'Anytime'
            }
          </button>
          {showDatePicker && (
            <div className={"tw-absolute tw-top-[105%]"}>
              <DateRangePicker
                startDatePlaceholderText={'Check In'}
                endDatePlaceholderText={'Check Out'}
                startDateId=""
                endDateId=""
                startDate={tempStartDate}
                endDate={tempEndDate}
                customArrowIcon={" "}
                //showClearDates={true}
                firstDayOfWeek={1}
                displayFormat={'DD MMM YYYY'}
                hideKeyboardShortcutsPanel={true}
                numberOfMonths={numberOfMonthsInCalendar}
                onDatesChange={handleDatesChange}
                focusedInput={focusedInput}
                onFocusChange={focusedInput => {
                  setFocusedInput(focusedInput);
                  if (!focusedInput) {
                    setShowDatePicker(false);
                  }
                }}
                renderCalendarInfo={renderCalendarInfo}
                customInputIcon={null} 
              />
            </div>
          )}
        </div>
      )}
    </>

  );
};

 

export default DatePicker;

const CustomInput = ({
  id,
  placeholder,
  focused,
  onFocus,
  onBlur,
  value,
  onChange, // You might not need this unless you're manually handling input changes
}) => {
  // Example of adding a custom style
  const inputStyle = {
    padding: '10px',
    fontSize: '16px',
    border: '1px solid #ccc',
    borderRadius: '4px',
  };

  return (
    <div>
      <input
        id={id}
        placeholder={placeholder}
        aria-label={placeholder} // Accessibility improvement
        style={inputStyle}
        value={value}
        onChange={onChange} // Handle changes if necessary
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {/* Display an icon or anything else you might want alongside the input */}
      {focused && <span>📅</span>}
    </div>
  );
};