import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './LanguageSwitcher.module.css';
import Dropdown from 'react-bootstrap/Dropdown';

export const LanguageSwitcher = (params) => {
   
 

 
  

  return(
    <div className={styles.LanguageSwitcher + " tw-mb-2 lg:tw-m-0"}>
      {/*<Dropdown >
        <Dropdown.Toggle variant="success" id="dropdown-basic" menuVariant="dark" >
          {params.language}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {Object.keys(params.languages).map((keyName, i) => (
            <Dropdown.Item className='!tw-text-black' onClick={() => setSelectedItem(keyName)}  key={i}>
              {keyName}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
          </Dropdown>*/}
      <select className="form-select">
        {Object.keys(params.languages).map((keyName, i) => (
          <option className='!tw-text-black' key={i}>
            {keyName}
          </option>
        ))}
      </select>
    </div>
  )
};

LanguageSwitcher.propTypes = {};

LanguageSwitcher.defaultProps = {};

export default LanguageSwitcher;
