import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { SiteSettingsContext } from 'contexts/SiteSettingsProvider';
import Search from 'pages/Search/SearchMap/SearchMap';
import ContentPage from 'pages/ContentPage/ContentPage';
import LocationPage from 'pages/Location/Location';
import PropertyPage from 'pages/Property/Property';
import HomePage from 'pages/HomePage/HomePage';
import FolderPage from 'pages/FolderPage/FolderPage';
import InspirationPage from 'pages/InspirationPage/InspirationPage';
import WishlistPage from 'pages/WishlistPage/WishlistPage';

import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from 'components/../../tailwind.config.js'
const fullConfig = resolveConfig(tailwindConfig);
let sm = parseInt(fullConfig.theme.screens.sm);
let md = parseInt(fullConfig.theme.screens.md);
let lg = parseInt(fullConfig.theme.screens.lg);
let xl = parseInt(fullConfig.theme.screens.xl);
window.sm = sm;
window.md = md;
window.lg = lg;
window.xl = xl;

function TemplateSelector({ templateType, pageId }) {
    const siteSettings = useContext(SiteSettingsContext);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const altTemplate = queryParams.get('alttemplate');
    if (!siteSettings || !pageId) {
        return
    }
    const keyID = siteSettings?.Key;
    const googleMapAPIKey = siteSettings?.SiteSettings?.GoogleMapAPIKey;
    const currency = siteSettings?.Currencies;

    if (altTemplate) {
        switch (altTemplate) {
            case 'Enquiry':
                window.location.href = window.location.pathname;
                return null;
            case 'checkout_guest':
                window.location.href = window.location.pathname;
                return null;
            default:
                break;
        }
    }


    switch (templateType) {
        case 'Search':
            return (
                <div>
                    <Search pageId={pageId} keyID={keyID} GoogleMapAPIKey={googleMapAPIKey} Currency={currency} />
                </div>
            );
        case 'ContentPage':
            return (
                <div>
                    <ContentPage pageId={pageId} keyID={keyID} />
                </div>
            );
        case 'Location':
            return (
                <div>
                    <LocationPage pageId={pageId} keyID={keyID} />
                </div>
            ); 
        case 'AProperty':
            return (
                <div>
                    <PropertyPage pageId={pageId} keyID={keyID} GoogleMapAPIKey={googleMapAPIKey} />
                </div>
            );     
        case 'Home':
            return (
                <div>
                    <HomePage pageId={pageId} keyID={keyID} />
                </div>
            );     
        case 'PageFolder':
            return (
                <div>
                    <FolderPage pageId={pageId} keyID={keyID} />
                </div>
            );    
        case 'Inspiration':
            return (
                <div>
                    <InspirationPage pageId={pageId} keyID={keyID} />
                </div>
            );             
        case 'Wishlist':
            return (
                <div>
                    <WishlistPage pageId={pageId} keyID={keyID} />
                </div>
            );

        // More cases and templates here
        default:
            window.location.href = window.location.pathname;
            return null;
    }
}

export default TemplateSelector;