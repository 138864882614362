import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import './description.css';

const Description = ({ intro, description, cons, pros, isLoading }) => {
    const [isDescriptionShown, setDescriptionShown] = useState(false);

    useEffect(() => {
        if (!intro && description) {
            setDescriptionShown(true);
        }
    }, [intro, description]);

    const toggleDescription = () => {
        setDescriptionShown(!isDescriptionShown);
    };

    const descriptionStyle = isDescriptionShown
        ? {}
        : { maxHeight: '300px', overflow: 'hidden' };

    return (
        <div>
            {intro && <h4 className="tw-text-primary !tw-text-left tw-font-monserrat tw-font-light">{intro}</h4>}
            <div className="description-wrapper" style={descriptionStyle}>
                <div dangerouslySetInnerHTML={{ __html: description }} />
                {!isDescriptionShown && <div className="fade-out"></div>}
            </div>
            {(intro && description) &&
                <button
                    onClick={toggleDescription}
                    className="tw-mb-4 tw-text-primary tw-border-b tw-border-primary"
                >
                    {isDescriptionShown ? 'Show Less' : 'Read More'}
                </button>
            }
            {((cons && cons.length > 0) || (pros && pros.length > 0)) && (
                <div className="tw-flex tw-flex-col tw-gap-4 tw-p-4 tw-bg-white tw-border-l-4 tw-border-slate-200">
                    {cons && cons.length > 0 && (
                        <div>
                            <h2 className="tw-text-lg tw-font-semibold tw-mb-0">Highlights of the Property</h2>
                            <p className="tw-text-base">{cons}</p>
                        </div>
                    )}
                    {pros && pros.length > 0 && (
                        <div>
                            <h2 className="tw-text-lg tw-font-semibold tw-mb-0">What Guests Should Know?</h2>
                            <p className="tw-text-base">{pros}</p>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Description;